import type { ParsedAccountDatum } from "@saberhq/sail";
import { TokenAmount } from "@saberhq/token-utils";
import * as Sentry from "@sentry/react";
import type { Farmer, Plot, VaultData } from "@sunnyaggregator/sunny-sdk";
import { Payroll, SBR_ADDRESS } from "@sunnyaggregator/sunny-sdk";
import BN from "bn.js";
import { zip } from "lodash";
import { useCallback, useMemo } from "react";

import { useToken } from "../../utils/farming/useToken";
import type { GetAllClaimableAmounts } from "./types";

export const useGetAllClaimableAmountsSBR = ({
  plotsData,
  farmersData,
  vaultsData,
}: {
  plotsData: ParsedAccountDatum<Plot>[];
  farmersData: ParsedAccountDatum<Farmer>[];
  vaultsData: ParsedAccountDatum<VaultData>[];
}): {
  getAllClaimableAmounts: GetAllClaimableAmounts;
} => {
  const { token: sbr } = useToken(SBR_ADDRESS);

  const payrolls = useMemo(() => {
    return zip(plotsData, farmersData, vaultsData).map(
      ([plot, farmer, vault]) => {
        const plotData = plot?.accountInfo.data;
        const farmerData = farmer?.accountInfo.data;
        const vaultData = vault?.accountInfo.data;
        const payroll = plotData
          ? new Payroll(
              plotData.famineTs,
              plotData.lastUpdateTs,
              plotData.dailyRewardsRate.div(new BN(86_400)),
              plotData.rewardsPerTokenStored,
              new BN(plotData.tokenMintDecimals),
              plotData.totalTokensDeposited
            )
          : null;
        return { payroll, farmerData, vaultData };
      }
    );
  }, [farmersData, plotsData, vaultsData]);

  const getAllClaimableAmounts = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    (additionalMilliseconds: number = 0) => {
      if (!sbr) {
        return null;
      }

      const now = new BN(
        Math.floor((new Date().getTime() + additionalMilliseconds) / 1000)
      );
      const allClaimable = payrolls.map(
        ({ payroll, farmerData, vaultData }) => {
          if (!payroll || !farmerData || !vaultData) {
            return null;
          }
          const wages = payroll.calculateWagesEarned(
            now,
            vaultData.stakedBalance,
            farmerData.wagesPerTokenPaid,
            farmerData.wagesEarned
          );
          try {
            return new TokenAmount(sbr, wages);
          } catch (e) {
            console.error(`Could not parse claimable amount`, e);
            Sentry.captureException(e as Error, {
              tags: {
                action: "plot.getClaimableAmount",
              },
              extra: {
                payrollFamineTs: payroll.famineTs.toString(),
                payrollLastCheckpointTs: payroll.lastCheckpointTs.toString(),
                payrollRewardsRatePerSecond:
                  payroll.rewardsRatePerSecond.toString(),
                payrollRewardsPerTokenStored:
                  payroll.rewardsPerTokenStored.toString(),
                payrollTokenDecimals: payroll.tokenDecimals.toString(),
                payrollTotalTokensDeposited:
                  payroll.totalTokensDeposited.toString(),

                currentTS: now.toString(),
                tokensDeposited: vaultData.stakedBalance.toString(),
                wagesPerTokenPaid: farmerData.wagesPerTokenPaid.toString(),
                wagesEarned: farmerData.wagesEarned.toString(),
                wages: wages.toString(),
              },
            });
            return null;
          }
        }
      );

      const allAmounts = allClaimable.filter((t): t is TokenAmount => !!t);
      const sum =
        allAmounts.length > 0
          ? allAmounts.reduce((acc, el) => acc.add(el))
          : null;
      return {
        sum,
        allClaimable,
      };
    },
    [payrolls, sbr]
  );

  return {
    getAllClaimableAmounts,
  };
};
