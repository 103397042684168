import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { TokenAmount } from "@saberhq/token-utils";
import React, { useMemo, useState } from "react";
import invariant from "tiny-invariant";

import { usePlot } from "../../../contexts/plot";
import { getMarket } from "../../../utils/currencies";
import { useStableSwap } from "../../../utils/useStableSwap";
import { useUserAssociatedTokenAccounts } from "../../../utils/useUserAssociatedTokenAccounts";
import { AsyncButton } from "../../common/AsyncButton";
import { TokenAmountSelector } from "../../common/TokenAmountSelector";

export const DepositWidget: React.FC = () => {
  const { exchange, virtualPrice } = useStableSwap();
  const [poolTokenAccount] = useUserAssociatedTokenAccounts([
    exchange?.lpToken,
  ]);

  const [depositAmountStr, setDepositAmountStr] = useState<string>("");

  const lpToken = exchange?.lpToken;
  const { stake } = usePlot();

  const depositAmount = useMemo(() => {
    if (!lpToken) {
      return null;
    }
    try {
      return TokenAmount.parse(lpToken, depositAmountStr);
    } catch (e) {
      // ignore
    }
  }, [depositAmountStr, lpToken]);

  const stakeDisabledReason =
    !depositAmount || depositAmount?.equalTo(0)
      ? "Enter amount to deposit"
      : null;
  const tokenA = exchange?.tokens[0];

  return (
    <DepositWidgetWrapper>
      <InnerSection>
        <TokenAmountSelector
          selectedValue={lpToken ?? null}
          inputValue={depositAmountStr}
          inputOnChange={setDepositAmountStr}
          currentAmount={{
            amount: poolTokenAccount?.balance,
            label: "Balance",
            allowSelect: true,
          }}
          // onSelect={() => {
          //   console.log(
          //     "SUNNYTODO: Allow users to pick from depositable coins and SLP, but not other coins that would require a swap."
          //   );
          // }}
          currency={tokenA ? getMarket(tokenA) : undefined}
          price={virtualPrice}
        />
      </InnerSection>
      <DepositButtonWrapper>
        <AsyncButton
          size="small"
          disabled={!!stakeDisabledReason}
          onClick={async () => {
            invariant(depositAmount, "no deposit amount");
            await stake(depositAmount);
            setDepositAmountStr("");
          }}
          css={css`
            width: 100%;
          `}
        >
          {stakeDisabledReason ?? "Deposit Saber LP"}
        </AsyncButton>
      </DepositButtonWrapper>
    </DepositWidgetWrapper>
  );
};

const DepositWidgetWrapper = styled.div`
  background: blue;
  padding: 12px;
  background: #f8f8f9;
  border-radius: 12px;
`;
const DepositButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerSection = styled.div``;
