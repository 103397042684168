import styled from "@emotion/styled";
import React, { useMemo } from "react";

import { usePlot } from "../../../contexts/plot";
import { useSunnyPools } from "../../../contexts/sunnyPools";
import { useAmountClaimableTooltip } from "../../../utils/farming/useAmountClaimableTooltip";
import { ClaimAllRewardsButton } from "../HomePage/ClaimAllRewardsButton";
import { HoverTooltip } from "../HomePage/HoverTooltip";
import { PoolRewards } from "./PoolRewards";

export const RewardsWidget: React.FC = () => {
  const { sbrRewards, sunnyRewards } = useSunnyPools();
  const { sunnyPool } = usePlot();

  const { totalSBR, totalSUNNY } = useMemo(() => {
    return {
      totalSBR: sunnyPool
        ? () => sbrRewards.amounts?.()?.pools[sunnyPool.index]
        : undefined,
      totalSUNNY: sunnyPool
        ? () => sunnyRewards.amounts?.()?.pools[sunnyPool.index]
        : undefined,
    };
  }, [sbrRewards, sunnyPool, sunnyRewards]);

  const claimAllRewardsTooltip = useAmountClaimableTooltip({
    totalSBR,
    totalSUNNY,
    rateSBR: sunnyPool ? sbrRewards.rates?.pools[sunnyPool.index] : undefined,
    rateSUNNY: sunnyPool
      ? sunnyRewards.rates?.pools[sunnyPool.index]
      : undefined,
  });

  return (
    <>
      <RewardsNumbersSpacer>
        <PoolRewards />
      </RewardsNumbersSpacer>
      <HoverTooltip message={claimAllRewardsTooltip}>
        <ClaimAllRewardsButton disabled={claimAllRewardsTooltip !== null} />
      </HoverTooltip>
    </>
  );
};

const RewardsNumbersSpacer = styled.div`
  padding-top: 3px;
  padding-bottom: 16px;
`;
