import { PublicKey } from "@solana/web3.js";

export const TX_REFETCH_TIME = 1_000;

export const API_BASE = `https://api.saber.so/api/v1`;

/**
 * SUNNY/USDC Serum market ID
 */
export const SERUM_MARKET_ID = new PublicKey(
  "Aubv1QBFh4bwB2wbP1DaPW21YyQBLfgjg8L4PHTaPzRc"
);

/**
 * Program ID of the Serum DEX
 */
export const SERUM_DEX_PROGRAM_ID = new PublicKey(
  "9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin"
);

/**
 * Link to the Bonfida interface
 */
export const SERUM_LINK = `https://dex.bonfida.org/#/market/${SERUM_MARKET_ID.toString()}`;

/**
 * Link to Aldrin SUNNY-USDC
 */
export const ALDRIN_LINK = `https://dex.aldrin.com/chart/spot/SUNNY_USDC`;

/**
 * Link to CoinGecko Sunny
 */
export const COINGECKO_LINK = `https://www.coingecko.com/en/coins/sunny-aggregator`;

/**
 * Link to CoinMarketCap Sunny
 */
export const COINMARKETCAP_LINK = `https://coinmarketcap.com/currencies/sunny-aggregator/`;
