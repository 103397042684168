import type { AccountParser } from "@saberhq/sail";
import type { KeyedAccountInfo } from "@solana/web3.js";
import type {
  MinerData,
  QuarryData,
  RewarderData,
} from "@sunnyaggregator/sunny-sdk";
import { useCallback } from "react";

import { useSDK } from "../../contexts/sdk";

interface MineParsers {
  parseMiner: AccountParser<MinerData>;
  parseQuarry: AccountParser<QuarryData>;
  parseRewarder: AccountParser<RewarderData>;
}

export const useMineParsers = (): MineParsers => {
  const { sunny } = useSDK();

  const mine = sunny.programs.Mine;
  const parseMiner = useCallback(
    (d: KeyedAccountInfo) =>
      mine.coder.accounts.decode<MinerData>("Miner", d.accountInfo.data),
    [mine.coder.accounts]
  );

  const parseQuarry = useCallback(
    (d: KeyedAccountInfo) =>
      mine.coder.accounts.decode<QuarryData>("Quarry", d.accountInfo.data),
    [mine.coder.accounts]
  );

  const parseRewarder = useCallback(
    (d: KeyedAccountInfo) =>
      mine.coder.accounts.decode<RewarderData>("Rewarder", d.accountInfo.data),
    [mine.coder.accounts]
  );

  return { parseMiner, parseQuarry, parseRewarder };
};
