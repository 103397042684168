import type {
  IExchange,
  IExchangeInfo,
  StableSwap,
} from "@saberhq/stableswap-sdk";
import type { Fraction } from "@saberhq/token-utils";
import { useCallback } from "react";
import { createContainer } from "unstated-next";

import { useAllPools } from "../utils/exchange/useAllPools";
import type { Pool } from "../utils/useEnvironment";

export type ExchangeMap = { [lpToken: string]: ExchangeInfo };

interface Router {
  loading: boolean;
  exchangeMap: ExchangeMap;
  getExchangeInfo: (exchange: IExchange) => ExchangeInfo | null;
}

interface ExchangeInfo {
  exchange: IExchange & Pool;
  swap: StableSwap;
  info: IExchangeInfo;
  virtualPrice: Fraction | null;
}

const useRouterInternal = (): Router => {
  const { poolsMap, loading } = useAllPools();
  const exchangeMap = poolsMap;
  const getExchangeInfo = useCallback(
    (exchange: IExchange): ExchangeInfo | null => {
      const exchangeConfig = exchange
        ? exchangeMap[exchange.lpToken.mintAccount.toString()]
        : null;
      return exchangeConfig ?? null;
    },
    [exchangeMap]
  );

  return {
    loading,
    exchangeMap,
    getExchangeInfo,
  };
};

export const { useContainer: useRouter, Provider: RouterProvider } =
  createContainer(useRouterInternal);
