import { useAccountData } from "@saberhq/sail";
import { deserializeMint, Token } from "@saberhq/token-utils";
import { PublicKey } from "@solana/web3.js";
import { useMemo } from "react";

import { useEnvironment } from "../useEnvironment";

export const useToken = (
  mint?: PublicKey | string
): { token: Token | null; loading: boolean } => {
  const { tokenMap, chainId } = useEnvironment();

  const mintKey = useMemo(() => {
    if (!mint) {
      return null;
    }
    try {
      return new PublicKey(mint);
    } catch (e) {
      console.warn("Could not parse token mint", e);
      return null;
    }
  }, [mint]);

  const token = useMemo(
    () => (mintKey ? tokenMap?.get(mintKey.toString()) : null),
    [mintKey, tokenMap]
  );
  const { data: mintData, loading } = useAccountData(mintKey);
  const mintInfo = useMemo(() => {
    try {
      return mintData ? deserializeMint(mintData.accountInfo.data) : null;
    } catch (e) {
      console.warn(
        `Could not deserialize mint for ${mint?.toString() ?? "(none)"}`
      );
      return null;
    }
  }, [mint, mintData]);

  const tokenRet = useMemo(() => {
    return (
      token ??
      (mintInfo && chainId && mintKey
        ? new Token({
            name: `Unknown Token ${mintKey.toString().slice(0, 5)}`,
            address: mintKey.toString(),
            decimals: mintInfo.decimals,
            symbol: `T${mintKey.toString().slice(0, 3)}`,
            chainId: chainId,
          })
        : null)
    );
  }, [chainId, mintInfo, mintKey, token]);

  return {
    loading,
    token: tokenRet,
  };
};
