import { useMemo } from "react";

import { useSunnyPools } from "../../../contexts/sunnyPools";
import { useClaimableAmounts } from "../../../contexts/sunnyPools/calculateClaimableAmounts";
import { formatDisplayWithSoftLimit } from "../../../utils/format";
import { RewardsNumbers } from "../HomePage/RewardsNumbers";

export const YourRewards: React.FC = () => {
  const { sbrRewards, sunnyRewards } = useSunnyPools();

  const sbrAmounts = useClaimableAmounts(sbrRewards.amounts);
  const sunnyAmounts = useClaimableAmounts(sunnyRewards.amounts);

  const { claimableSBR, claimableSUNNYRaw } = useMemo(() => {
    return {
      claimableSBR: sbrAmounts?.total,
      claimableSUNNYRaw: sunnyAmounts?.total,
    };
  }, [sbrAmounts?.total, sunnyAmounts?.total]);

  const dailySBR = sbrRewards.rates
    ? sbrRewards.rates.total * 86_400
    : undefined;
  const dailySUNNYRaw = sunnyRewards.rates
    ? sunnyRewards.rates.total * 86_400
    : undefined;

  const { claimableSUNNY, dailySUNNY } = useMemo(() => {
    return {
      claimableSUNNY: claimableSUNNYRaw,
      dailySUNNY: dailySUNNYRaw,
    };
  }, [claimableSUNNYRaw, dailySUNNYRaw]);

  if (claimableSUNNY && claimableSUNNY > 0.0000009) {
    document.title = `Sunny: ${formatDisplayWithSoftLimit(
      claimableSUNNY,
      6,
      3
    )} SUNNY`;
  } else if (claimableSBR && claimableSBR > 0.0000009) {
    document.title = `Sunny: ${formatDisplayWithSoftLimit(
      claimableSBR,
      6,
      3
    )} SBR`;
  } else {
    document.title = "Sunny Aggregator";
  }

  return (
    <RewardsNumbers
      amountSBR={claimableSBR}
      tooltipSBR={
        claimableSBR === undefined || dailySBR === undefined
          ? ["Earn SBR after depositing", "Saber LP into Sunny"]
          : [formatDisplayWithSoftLimit(dailySBR ?? 0, 6, 6) + " SBR/day"]
      }
      amountSUNNY={claimableSUNNY}
      tooltipSUNNY={
        claimableSUNNY === undefined || typeof dailySUNNY !== "number" // 0 is falsy but we still display 0.000000 SUNNY/day
          ? ["Earn SUNNY after depositing", "Saber LP into Sunny"]
          : [formatDisplayWithSoftLimit(dailySUNNY, 6, 6) + " SUNNY/day"]
      }
    />
  );
};
