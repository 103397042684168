import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useConnectionContext } from "@saberhq/use-solana";
import React, { useMemo } from "react";

import { usePrices } from "../../../contexts/prices";
import { PoolStatus, useSunnyPools } from "../../../contexts/sunnyPools";
import { breakpoints } from "../../../theme/breakpoints";
import {
  ALDRIN_LINK,
  COINGECKO_LINK,
  COINMARKETCAP_LINK,
  SERUM_LINK,
} from "../../../utils/constants";
import { CurrencyMarket } from "../../../utils/currencies";
import { useAmountClaimableTooltip } from "../../../utils/farming/useAmountClaimableTooltip";
import {
  formatCurrencySmart,
  formatDisplayWithSoftLimit,
} from "../../../utils/format";
import { isAldrinSupportedOnUsersBrowser } from "../../../utils/isAldrinSupported";
import { CardsContainer } from "../../common/cards/CardsContainer";
import { LoadingPage } from "../../common/LoadingPage";
import { LoadingShimmer } from "../../common/LoadingShimmer";
import { SunnyErrorBoundary } from "../../common/SunnyErrorBoundary";
import { MainLayout } from "../../layout/MainLayout";
import { ClaimAllRewardsButton } from "./ClaimAllRewardsButton";
import { FarmAccordionWrapper } from "./FarmAccordionWrapper";
import { HoverTooltip } from "./HoverTooltip";
import { RotatingNASASun } from "./RotatingNASASun";
import { YourRewards } from "./YourRewards";

export const HomePage: React.FC = () => {
  const {
    sunnyPools: plots,

    sbrRewards,
    sunnyRewards,

    tvlUSD,
  } = useSunnyPools();
  const {
    prices: {
      SUNNY: { price: sunnyPrice, loading: sunnyPriceLoading },
    },
  } = usePrices();
  const { network } = useConnectionContext();

  const useTooltipArgs = useMemo(() => {
    return {
      totalSBR: () => sbrRewards.amounts?.()?.total,
      totalSUNNY: () => sunnyRewards.amounts?.()?.total,
      rateSBR: sbrRewards.rates?.total,
      rateSUNNY: sunnyRewards.rates?.total,
    };
  }, [sbrRewards, sunnyRewards]);
  const claimAllRewardsTooltip = useAmountClaimableTooltip(useTooltipArgs);

  return (
    <MainLayout hideOptions>
      <HomePageBg>
        <RotatingNASASun />
        <SunWrapper>
          <PreMigration>
            <PreMigrationContent>
              <h3>Pre-migration app</h3>
              <p>
                If you have issues migrating your deposits on the latest app at
                sunny.ag, you can use this app as a workaround to access your
                deposits.
              </p>
              <p>
                Please be careful with security. There are scammers pretending
                to be the official @sunnyag account. Sunny has no Telegram
                channel. All communications are carried out through Discord and
                Twitter.{" "}
                <strong>
                  Official Sunny Aggregator accounts will NEVER private message
                  you first
                </strong>
                .
              </p>
            </PreMigrationContent>
          </PreMigration>
        </SunWrapper>
        <MainBodyBg>
          <MainBody>
            <AllRewardsBox>
              <AllRewardsShrinker>
                <RewardsHeader>Your Old Rewards</RewardsHeader>
                <RewardsNumbersSpacer>
                  <YourRewards />
                </RewardsNumbersSpacer>
                <LastPrice>
                  <SunnyPrice>
                    1 SUNNY =
                    <a
                      css={css`
                        margin-left: 4px;
                        min-width: 58px;
                      `}
                      href={
                        isAldrinSupportedOnUsersBrowser()
                          ? ALDRIN_LINK
                          : SERUM_LINK
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {sunnyPrice ? (
                        formatDisplayWithSoftLimit(
                          Number(sunnyPrice.toFixed(10)),
                          4,
                          3,
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )
                      ) : sunnyPriceLoading ? (
                        <LoadingShimmer
                          css={css`
                            margin-left: 1px;
                            width: 57px;
                          `}
                        />
                      ) : (
                        "--"
                      )}{" "}
                    </a>
                  </SunnyPrice>
                </LastPrice>
                <HoverTooltip message={claimAllRewardsTooltip}>
                  <ClaimAllRewardsButton
                    disabled={claimAllRewardsTooltip !== null}
                  />
                </HoverTooltip>
              </AllRewardsShrinker>
            </AllRewardsBox>
            {plots.length === 0 ? (
              <LoadingPageWrapper>
                <LoadingPage />
              </LoadingPageWrapper>
            ) : null}
            {plots.length > 0 && (
              <CardsContainer>
                {plots
                  .filter((plot) => {
                    if (network !== "mainnet-beta") {
                      return true;
                    }
                    // Manual override for people who accidentally deposited in a hidden pool
                    return (
                      "#" + plot.pool.id === window.location.hash ||
                      plot.status !== PoolStatus.Hidden
                    );
                  })
                  .map((plot, i) => {
                    const { pool, poolID, farmer, vault, sunnyPool } = plot;
                    return (
                      poolID &&
                      plot &&
                      pool &&
                      sunnyPool && (
                        <SunnyErrorBoundary key={poolID}>
                          <FarmAccordionWrapper
                            pool={pool}
                            farmer={farmer ?? undefined}
                            plot={plot}
                            vault={vault}
                            sunnyPool={sunnyPool}
                            totalPlotCount={plots.length}
                            css={css`
                              z-index: ${plots.length - i + 3};
                            `}
                          />
                        </SunnyErrorBoundary>
                      )
                    );
                  })}
              </CardsContainer>
            )}
          </MainBody>
          <TVL>
            <h2>Total Value Locked in old pools</h2>
            <p>
              {!tvlUSD || tvlUSD?.equalTo(0) ? (
                "..."
              ) : (
                <>{formatCurrencySmart(tvlUSD, CurrencyMarket.USD)}&nbsp;🚀</>
              )}
            </p>
          </TVL>
          <Footer>
            <FooterLink
              href="https://chat.sunny.ag/"
              target="_blank"
              rel="noreferrer"
            >
              Discord
            </FooterLink>
            <FooterLink
              href="https://twitter.com/SunnyAggregator"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </FooterLink>
            <FooterLink
              href="https://medium.com/sunny-aggregator"
              target="_blank"
              rel="noreferrer"
            >
              Blog
            </FooterLink>
            <FooterLink href={SERUM_LINK} target="_blank" rel="noopener">
              Serum
            </FooterLink>
            <FooterLink href={ALDRIN_LINK} target="_blank" rel="noopener">
              Aldrin DEX
            </FooterLink>
            <FooterLink href={COINGECKO_LINK} target="_blank" rel="noopener">
              CoinGecko
            </FooterLink>
            <FooterLink
              href={COINMARKETCAP_LINK}
              target="_blank"
              rel="noopener"
            >
              CoinMarketCap
            </FooterLink>
          </Footer>
        </MainBodyBg>
      </HomePageBg>
    </MainLayout>
  );
};

const HomePageBg = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  min-height: 1400px;
`;

const SunWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;

  padding-top: 74px;
  padding-bottom: 183px;
  z-index: 2;
  position: relative;

  ${breakpoints.medium} {
    // margin-top: -48px;
    // padding-top: 80px;
    // padding-bottom: 160px;
  }
  ${breakpoints.tablet} {
    padding-top: 52px;
    padding-bottom: 150px;
  }
  ${breakpoints.mobile} {
    padding-top: 52px;
    padding-bottom: 152px;
  }
`;
const PreMigration = styled.div`
  max-width: 610px;
  margin: 0 auto;
  padding: 0 24px;

  h3 {
    color: #000;
    text-align: center;
    font-weight: bold;
  }
`;
const PreMigrationContent = styled.div`
  background: #ffffff;
  border: 6px solid hsl(45, 100%, 50%);
  padding: 24px 32px 24px 32px;
  border-radius: 8px;

  ${breakpoints.mobile} {
    padding-left: 24px;
    padding-right: 24px;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
`;
const MainBodyBg = styled.div`
  // border-top: 1px solid #eaeaeb;
  // box-shadow: 0 0 50px rgba(255, 255, 255, 0.5);
  /* background: #fafafb; */
  /* background: rgba(0, 0, 0, 0.5); */
  position: relative;
  z-index: 2;
  /* backdrop-filter: blur(2px); */
`;
const MainBody = styled.div`
  max-width: 1060px;
  margin: 0 auto;

  /* background: blue; */
  /* background: #fafafb; */

  padding: 1px 32px 0 32px;
  ${breakpoints.mobile} {
    padding: 1px 24px 0 24px;
  }
`;
// Social items already took some of the lighter color orange,
// so the gradient starts a bit more reddish
const Tagline = styled.h2`
  color: #e29034;
  font-size: 32px;
  line-height: 1.25;
  text-align: center;
  margin-bottom: 8px;

  background: -webkit-linear-gradient(#ec4c27, #ec8834);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  ::selection {
    background: highlight;
    -webkit-text-fill-color: #fff;
  }

  ${breakpoints.tablet} {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
  }
  ${breakpoints.mobile} {
    font-size: 26px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const SocialItems = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  justify-items: center;
  justify-content: center;

  a {
    transition: all 0.2s;
    opacity: 0.9;
    padding: 10px 12px 16px 12px;
  }
  a:focus svg {
    color: #fff;
  }

  a:hover {
    transform: scale(1.2);
    opacity: 1;
  }
  svg {
    color: #e29034;
    height: 20px;
    width: 20px;
  }

  ${breakpoints.mobile} {
    a {
      padding: 6px;
    }
  }
`;

const LoadingPageWrapper = styled.div`
  padding-top: 44px;
  padding-bottom: 42px;
`;

const AllRewardsBox = styled.div`
  margin-top: -105px;
  margin-bottom: 40px;
  display: flex;

  // Full screen
  // margin-top: 0;
  // margin-bottom: 0;
  // border-radius: 0;
  // width: 100vw;
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
`;

const AllRewardsShrinker = styled.div`
  border-radius: 8px;
  margin: 0 auto;
  background: #fff;
  padding: 16px 24px;
  min-width: 0px;
  width: auto;
  display: inline-block;

  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.1s ease-in-out;
  :hover {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.18);
  }

  ${breakpoints.mobile} {
    padding: 16px;
  }
`;

const RewardsHeader = styled.p`
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  line-height: 1;
  margin-bottom: 8px;
`;

const RewardsNumbersSpacer = styled.div`
  padding-top: 8px;
  padding-bottom: 10px;
`;

const Footer = styled.div`
  display: flex;
  gap: 12px 24px;
  padding: 40px 20px;
  justify-content: center;
  flex-wrap: wrap;
`;

const FooterLink = styled.a`
  color: #e29034;
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;

const LastPrice = styled.div`
  color: #555;
  font-size: 14px;
  a {
    padding-bottom: 5px;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const SunnyPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 33px;
`;

const TVL = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin: 0px auto;

  margin-top: 48px;

  h2 {
    font-size: 20px;
    margin-bottom: 8px;
  }
  font-size: 36px;
  p {
    margin: 0;
    font-weight: 600;
  }
`;
