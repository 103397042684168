import { css } from "@emotion/react";
import { TransactionEnvelope } from "@saberhq/solana-contrib";
import { getOrCreateATAs } from "@saberhq/token-utils";
import { useSolana } from "@saberhq/use-solana";
import { CREATOR_KEY } from "@sunnyaggregator/sunny-sdk";
import React, { useCallback } from "react";
import invariant from "tiny-invariant";

import { useSDK } from "../../../contexts/sdk";
import { useSunnyPools } from "../../../contexts/sunnyPools";
import { AsyncButton } from "../../common/AsyncButton";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IClaimRewardsProps {
  disabled?: boolean;
}

export const ClaimAllRewardsButton: React.FC<IClaimRewardsProps> = ({
  disabled,
}: IClaimRewardsProps) => {
  const { sunnyMut, handleTx, handleTxs } = useSDK();
  const { sunnyPools } = useSunnyPools();
  const { providerMut } = useSolana();

  const claimAll = useCallback(async () => {
    invariant(sunnyMut, "sdk not connected");
    invariant(providerMut, "provider mut");
    const { instructions } = await getOrCreateATAs({
      provider: providerMut,
      mints: {
        sbr: sunnyMut.ssFarm.sbrAddress,
      },
    });
    if (instructions.length > 0) {
      await handleTx(
        new TransactionEnvelope(providerMut, instructions.slice()),
        "Create Rewards Token Accounts"
      );
    }

    const claims = (
      await Promise.all(
        sunnyPools.map(async (plot) => {
          if (!plot.pool) {
            return [];
          }
          const pool = await sunnyMut.ssFarm.findPoolAddress({
            creator: CREATOR_KEY,
            lpMint: plot.pool.lpToken.mintAccount,
          });
          const vault = await sunnyMut.ssFarm.findVaultAddress({
            pool,
          });
          // if vault does not exist, then there is definitely nothing to claim
          if (!(await sunnyMut.provider.connection.getAccountInfo(vault))) {
            return [];
          }
          const theVault = await sunnyMut.ssFarm.loadVault({ vaultKey: vault });
          return [
            await theVault.claimMineRewards(),
            await theVault.claimFarmRewards(),
          ];
        })
      )
    ).flat();
    const claimsFiltered = claims.filter(
      (claim): claim is TransactionEnvelope => !!claim
    );
    await handleTxs(claimsFiltered, "Claim All");
  }, [sunnyMut, providerMut, sunnyPools, handleTxs, handleTx]);

  return (
    <AsyncButton
      size="small"
      disabled={disabled}
      onClick={claimAll}
      css={css`
        width: 100%;
      `}
      // connectWalletOverride={"Claim all rewards"}
    >
      Claim old rewards
    </AsyncButton>
  );
};

// SUNNYTODO: Somehow make the button say "No rewards yet" when the user has 0 rewards to claim
