import styled from "@emotion/styled";
import { Percent } from "@saberhq/token-utils";
import { Switch } from "antd";
import React, { useState } from "react";
import { Route } from "react-router-dom";

import { IWithdrawalMode, useSettings } from "../../../contexts/settings";
import type { IProps as IModalProps } from "../Modal";
import { Modal } from "../Modal";
import { ButtonGroup } from "./ButtonGroup";

const SLIPPAGE_PRESETS = ["0.5", "1.0", "2.0"];

type IProps = Omit<IModalProps, "children" | "title">;

export const AdvancedSettingsModal: React.FC<IProps> = ({
  ...modalProps
}: IProps) => {
  const {
    maxSlippagePercent,
    setMaxSlippagePercent,
    withdrawalMode,
    setWithdrawalMode,
    includeWrapped,
    setIncludeWrapped,
  } = useSettings();
  const [slippage, setSlippage] = useState<string>(
    maxSlippagePercent.toFixed(1)
  );

  const onSelect = (slippage: string) => {
    setSlippage(slippage);
    try {
      setMaxSlippagePercent(new Percent(parseFloat(slippage) * 100, 10_000));
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  return (
    <Modal title="Settings" {...modalProps} darkenOverlay={false}>
      <Settings>
        <Setting>
          <OptionLabel>Max Price Impact</OptionLabel>
          <ButtonGroup
            value={slippage}
            onSelect={onSelect}
            options={[
              ...SLIPPAGE_PRESETS.map((s) => ({
                key: s,
                label: `${s}%`,
              })),
            ]}
            hasCustomFallback
          />
        </Setting>
        <Route path="/pools/:poolID/withdraw">
          <Setting>
            <OptionLabel>Withdrawal Currency</OptionLabel>
            <ButtonGroup
              value={withdrawalMode}
              onSelect={setWithdrawalMode}
              options={[
                {
                  key: IWithdrawalMode.ALL,
                  label: "All Currencies",
                },
                {
                  key: IWithdrawalMode.ONE,
                  label: "One Currency",
                },
              ]}
              optionPadding={35}
            />
          </Setting>
        </Route>
        <Setting>
          <OptionLabel>
            Always include decimal wrapped tokens in list?
          </OptionLabel>
          <Switch checked={includeWrapped} onChange={setIncludeWrapped} />
        </Setting>
      </Settings>
    </Modal>
  );
};

const Settings = styled.div`
  display: grid;
  grid-row-gap: 18px;
  grid-auto-flow: row;
`;

const Setting = styled.div``;

const OptionLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: block;
  margin-bottom: 12px;
`;
