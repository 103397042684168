import styled from "@emotion/styled";
import type { Token } from "@saberhq/token-utils";
import React from "react";

import { breakpoints } from "../../theme/breakpoints";
import { TokenIcon } from "./TokenIcon";

interface Props {
  tokens: readonly Token[];
}

export const PoolIcon: React.FC<Props> = ({ tokens }: Props) => {
  return (
    <Wrapper>
      {tokens.map((tok) => (
        <TokenIcon
          key={tok.mintAccount.toString()}
          src={tok.icon ?? "/tokens/slp.png"}
          alt={`Icon of token ${tok.name} (${tok.symbol})`}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  user-select: none;
  display: flex;
  gap: 0;
  align-items: center;
  height: 24px;
  & > img {
    height: 100%;
    margin-left: -6px;
    &:first-of-type {
      margin-left: 0;
    }
  }
  ${breakpoints.mobile} {
    flex-direction: column;
    height: 48px;

    & > img {
      height: 100%;
      margin-left: 0;
      margin-top: -6px;
      height: 24px;
      &:first-of-type {
        margin-left: 0;
        margin-top: 3px;
      }
    }
  }
`;
