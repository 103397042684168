import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Token } from "@saberhq/token-utils";
import React from "react";

import { TokenIcon } from "../TokenIcon";

interface IProps {
  token: Token;
}

export const TokenInfo: React.FC<IProps> = ({ token }: IProps) => (
  <TokenInfoWrapper>
    {token ? (
      <TokenIcon
        css={css`
          width: 32px;
          height: 32px;
        `}
        src={token.icon}
        alt={`Icon of token ${token.symbol}`}
      />
    ) : (
      <div />
    )}
    <TokenMeta>
      <TokenSymbol>
        {token?.symbol === "SLP" ? "Saber LP" : token?.symbol}
      </TokenSymbol>
      <TokenName>{token?.name.replace(" Saber LP", "")}</TokenName>
    </TokenMeta>
  </TokenInfoWrapper>
);

const TokenInfoWrapper = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 32px 1fr;
  align-items: center;
`;

const TokenMeta = styled.div``;

const TokenSymbol = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16.71px;
  color: ${({ theme }) => theme.colors.text.bold};
`;

const TokenName = styled.div`
  font-size: 12px;
  line-height: 14.32px;
  margin-top: 1px;

  color: ${({ theme }) => theme.colors.text.default};
`;
