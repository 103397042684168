import { useMemo } from "react";

import type { PreciseClaimableAmounts } from "./calculateClaimableAmounts";
import { useGetPreciseClaimableAmounts } from "./calculateClaimableAmounts";
import type { RewardRates } from "./calculateRewardRates";
import { calculateRewardRates } from "./calculateRewardRates";
import type { GetAllClaimableAmounts } from "./types";

interface UseAllRewardsArgs {
  getAllClaimableAmountsSBR: GetAllClaimableAmounts;
  getAllClaimableAmountsSUNNY: GetAllClaimableAmounts;
}

export interface RewardsAndRates {
  amounts: () => PreciseClaimableAmounts | null;
  rates: RewardRates | null;
}

/**
 * Does some magical filtering to hide jumpyness. Prioritizing smoothness and
 * precision over accuracy
 *
 * @param getAmount Either getClaimableAmount or getTotalClaimableAmount
 * @returns
 */
export const useAllRewards = ({
  getAllClaimableAmountsSBR,
  getAllClaimableAmountsSUNNY,
}: UseAllRewardsArgs): {
  sbr: RewardsAndRates;
  sunny: RewardsAndRates;
} => {
  const { sbrRates, sunnyRates } = useMemo(() => {
    const sbrRates = calculateRewardRates(getAllClaimableAmountsSBR);
    const sunnyRates = calculateRewardRates(getAllClaimableAmountsSUNNY);
    return { sbrRates, sunnyRates };
  }, [getAllClaimableAmountsSBR, getAllClaimableAmountsSUNNY]);

  const sbr = useGetPreciseClaimableAmounts(sbrRates);
  const sunny = useGetPreciseClaimableAmounts(sunnyRates);

  return {
    sbr: {
      amounts: sbr,
      rates: sbrRates,
    },
    sunny: {
      amounts: sunny,
      rates: sunnyRates,
    },
  };
};
