import styled from "@emotion/styled";
import type { ParsedAccountDatum, ParsedAccountInfo } from "@saberhq/sail";
import type { Farmer, PoolData, VaultData } from "@sunnyaggregator/sunny-sdk";
import React, { useMemo } from "react";

import { PlotProvider } from "../../../contexts/plot";
import type { SunnyPool } from "../../../contexts/sunnyPools";
import { calculatePlotStats } from "../../../utils/farming/calculatePlotStats";
import { useGovernanceToken } from "../../../utils/farming/useGovernanceToken";
import { useLandlordState } from "../../../utils/farming/useLandlordState";
import type { Pool } from "../../../utils/useEnvironment";
import { StableSwapProvider } from "../../../utils/useStableSwap";
import { FarmAccordionMain } from "./FarmAccordionMain";

interface Props {
  className?: string;
  plot: SunnyPool;
  pool: Pool;
  farmer?: Farmer;
  vault: ParsedAccountDatum<VaultData>;
  sunnyPool: ParsedAccountInfo<PoolData>;
  totalPlotCount: number; // Used to expand by default when only 1 is available
}

export const FarmAccordionWrapper: React.FC<Props> = ({
  pool,
  className,
  farmer,
  plot,
  vault,
  sunnyPool,
  totalPlotCount,
}: Props) => {
  const { state: landlord } = useLandlordState();
  const { token: sbr } = useGovernanceToken();

  const vaultFarmer = plot.vaultFarmer;

  const stats = useMemo(() => {
    return calculatePlotStats({
      landlord,
      plot: plot.plot.accountInfo.data,
      farmer: vaultFarmer?.accountInfo.data,
      token: pool.lpToken,
      sbr,
      farmerBalance: plot.vault?.accountInfo.data.stakedBalance,
    });
  }, [landlord, plot, pool.lpToken, sbr, vaultFarmer?.accountInfo.data]);

  // Follow this guide for accordions: https://www.hassellinclusion.com/blog/accessible-accordion-pattern/
  return (
    <Wrapper className={className}>
      <StableSwapProvider initialState={{ exchange: pool }}>
        <PlotProvider initialState={{ plot }}>
          <FarmAccordionMain
            pool={pool}
            farmer={farmer}
            plot={plot}
            stats={stats}
            vault={vault}
            sunnyPool={sunnyPool}
            totalPlotCount={totalPlotCount}
          />
        </PlotProvider>
      </StableSwapProvider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.1s ease-in-out;
  :hover {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.18);
  }
`;
