import type { Token } from "@saberhq/token-utils";
import { SBR_ADDRESS } from "@sunnyaggregator/sunny-sdk";

import { useToken } from "./useToken";

/**
 * Governance token loading
 * @returns
 */
export const useGovernanceToken = (): {
  loading: boolean;
  token: Token | null;
} => {
  return useToken(SBR_ADDRESS);
};
