import { Fraction } from "@saberhq/token-utils";
import { useMemo } from "react";
import { createContainer } from "unstated-next";

import { SERUM_MARKET_ID } from "../../utils/constants";
import { CurrencyMarket } from "../../utils/currencies";
import type { CoingeckoPrices } from "./coingecko";
import { useCoingeckoPrices } from "./coingecko";
import { useSerumPrice } from "./serum";

interface Prices extends CoingeckoPrices {
  prices: {
    [M in CurrencyMarket]: {
      price: Fraction | null;
      loading: boolean;
    };
  };
}

const usePricesInternal = (): Prices => {
  const sunnyPrice = useSerumPrice(SERUM_MARKET_ID);
  const coingeckoPrices = useCoingeckoPrices();

  const prices: Prices["prices"] = useMemo(
    () => ({
      [CurrencyMarket.BTC]: coingeckoPrices.btc,
      [CurrencyMarket.LUNA]: coingeckoPrices.luna,
      [CurrencyMarket.SOL]: coingeckoPrices.sol,
      [CurrencyMarket.FTT]: {
        price: coingeckoPrices.fttPriceUSD,
        loading: coingeckoPrices.fttPriceLoading,
      },
      [CurrencyMarket.SRM]: {
        price: coingeckoPrices.serumPriceUSD,
        loading: coingeckoPrices.serumPriceLoading,
      },
      [CurrencyMarket.USD]: {
        price: new Fraction(1),
        loading: false,
      },
      [CurrencyMarket.SUNNY]: sunnyPrice,
    }),
    [
      coingeckoPrices.btc,
      coingeckoPrices.luna,
      coingeckoPrices.sol,
      coingeckoPrices.fttPriceLoading,
      coingeckoPrices.fttPriceUSD,
      coingeckoPrices.serumPriceLoading,
      coingeckoPrices.serumPriceUSD,
      sunnyPrice,
    ]
  );

  return {
    ...coingeckoPrices,
    prices,
  };
};

export const { Provider: PricesProvider, useContainer: usePrices } =
  createContainer(usePricesInternal);
