import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { DEFAULT_TOKEN_DECIMALS } from "@saberhq/stableswap-sdk";
import type { Percent, Token } from "@saberhq/token-utils";
import { Fraction, TokenAmount } from "@saberhq/token-utils";
import React from "react";

import { BREAKPOINT_SIZES } from "../../../theme/breakpoints";
import { CurrencyMarket, getMarket } from "../../../utils/currencies";
import { formatCurrencySmart } from "../../../utils/format";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { BigNumericInput } from "../inputs/BigNumericInput";
import { Slippage } from "../Slippage";
import { TokenAmountDisplay } from "../TokenAmountDisplay";
import { TokenDropdown } from "../TokenDropdown";

interface IProps {
  tokens?: readonly Token[];
  onSelect?: (token: Token) => void;
  selectedValue: Token | null;
  inputValue: string;
  inputOnChange?: (val: string) => void;
  slippage?: Percent;
  inputDisabled?: boolean;
  className?: string;

  currentAmount?: {
    amount?: TokenAmount;
    allowSelect?: boolean;
    label?: string;
  };
  currency?: CurrencyMarket;
  price?: Fraction;
}

/**
 * Selects a token and its amount
 * @param param0
 * @returns
 */
export const TokenAmountSelector: React.FC<IProps> = ({
  tokens,
  onSelect,
  selectedValue,
  inputValue,
  inputOnChange,
  slippage,
  inputDisabled = false,
  currentAmount,
  currency = selectedValue ? getMarket(selectedValue) : CurrencyMarket.USD,
  className,
  price,
}: IProps) => {
  const { width } = useWindowDimensions();

  const uiDecimals =
    width < BREAKPOINT_SIZES[0]
      ? 4
      : selectedValue?.decimals ?? DEFAULT_TOKEN_DECIMALS;

  const outputParsed = parseFloat(inputValue);
  const output = Number.isNaN(outputParsed)
    ? undefined
    : price?.multiply(Fraction.fromNumber(outputParsed));

  return (
    <TokenBox className={className}>
      <TokenAndInput>
        <TokenDropdown
          tokens={tokens}
          token={selectedValue}
          onChange={onSelect}
        />
        <BigNumericInput
          css={css`
            text-align: right;
            width: 100%;
            flex-grow: 0;
          `}
          placeholder="0"
          disabled={inputDisabled}
          value={inputValue}
          onChange={inputOnChange}
        />
      </TokenAndInput>
      {selectedValue && (
        <BalanceAndOutputOverflowPrevention>
          <BalanceAndOutput>
            {currentAmount ? (
              <Balance>
                <span>{currentAmount.label ?? "Balance"}:</span>
                {currentAmount.amount ? (
                  <TextButton
                    onClick={
                      currentAmount.allowSelect
                        ? () => {
                            inputOnChange?.(
                              currentAmount.amount?.toExact() ?? "0"
                            );
                          }
                        : undefined
                    }
                  >
                    <TokenAmountDisplay
                      amount={
                        currentAmount.amount ??
                        new TokenAmount(selectedValue, 0)
                      }
                      locale="en-US"
                      numberFormatOptions={{
                        minimumFractionDigits: uiDecimals,
                        maximumFractionDigits: uiDecimals,
                      }}
                    />
                  </TextButton>
                ) : (
                  <NoAmount>--</NoAmount>
                )}
              </Balance>
            ) : (
              <div />
            )}
            {output ? (
              <Output>
                ~{formatCurrencySmart(output, currency)}
                {slippage !== undefined && (
                  <Slippage
                    css={css`
                      margin-left: 3px;
                    `}
                    value={slippage}
                    showParens
                  />
                )}
              </Output>
            ) : (
              ""
              // <Output>{CURRENCY_INFO[currency].prefix ?? ""}&mdash;</Output>
            )}
          </BalanceAndOutput>
        </BalanceAndOutputOverflowPrevention>
      )}
    </TokenBox>
  );
};

const BalanceAndOutputOverflowPrevention = styled.div`
  position: relative;
  padding: 8px 0;
  height: 36px; // 20px line height 8px padding
`;
const BalanceAndOutput = styled.div`
  position: absolute;
  left: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  line-height: 20px;

  gap: 8px;
  white-space: nowrap;
`;
const Output = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.default};
`;

const NoAmount = styled.span`
  margin-left: 0.3em;
  color: ${({ theme }) => theme.colors.text.default};
`;

const TextButton = styled.button<{ onClick?: () => void }>`
  margin-left: 0.3em;
  color: ${({ theme }) => theme.colors.text.accent};
  ${({ onClick }) =>
    onClick !== undefined &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}

  padding: 0;
  border: 0;
  display: inline;
  background: none;
`;

const TokenAndInput = styled.section`
  display: flex;
  gap: 12px;
`;

const Balance = styled.div`
  font-weight: normal;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.default};

  display: flex;
  align-items: center;
`;

const TokenBox = styled.div``;
