import type { ParsedAccountDatum, ParsedAccountInfo } from "@saberhq/sail";
import { useParsedAccountsData } from "@saberhq/sail";
import { Fraction, TokenAmount } from "@saberhq/token-utils";
import type { PublicKey } from "@solana/web3.js";
import type {
  Farmer,
  MegaBPS,
  MinerData,
  Plot,
  PoolData,
  QuarryData,
  VaultData,
} from "@sunnyaggregator/sunny-sdk";
import { LANDLORD_KEY } from "@sunnyaggregator/sunny-sdk";
import { zip } from "lodash";
import { useMemo } from "react";
import { createContainer } from "unstated-next";

import { getMarket } from "../../utils/currencies";
import type { PoolID } from "../../utils/environments";
import { useAllPoolsWithUserBalances } from "../../utils/exchange/useAllPoolsWithUserBalances";
import { useMineParsers } from "../../utils/farming/useMineParsers";
import { useSaberParsers } from "../../utils/farming/useSaberParsers";
import { useSunnyParsers } from "../../utils/farming/useSunnyParsers";
import type { KnownPool } from "../../utils/useEnvironment";
import type { ProgramAddressInput } from "../../utils/useProgramAddresses";
import {
  ProgramAddressType,
  useProgramAddresses,
} from "../../utils/useProgramAddresses";
import { usePrices } from "../prices";
import { useRouter } from "../router";
import { useSDK } from "../sdk";
import type { RewardsAndRates } from "./useAllRewards";
import { useAllRewards } from "./useAllRewards";
import { useGetAllClaimableAmountsSBR } from "./useGetAllClaimableAmountsSBR";
import { useGetTotalClaimableAmountSUNNY } from "./useGetAllClaimableAmountsSUNNY";

export enum PoolStatus {
  Hidden = "hidden",
  Pending = "pending",
  Active = "active",
}

export interface SunnyPool {
  index: number;
  key: PublicKey;
  poolID: PoolID;
  pool: KnownPool;
  plot: ParsedAccountInfo<Plot>;
  farmer: Farmer | null;

  vault: ParsedAccountDatum<VaultData>;
  vaultFarmer: ParsedAccountDatum<Farmer>;
  sunnyPool: ParsedAccountInfo<PoolData>;

  quarry: ParsedAccountInfo<QuarryData>;
  miner: ParsedAccountDatum<MinerData>;

  status: PoolStatus;
  poolValueLockedUSD?: Fraction;
}

/**
 * useAllPlots
 * @returns
 */
const useSunnyPoolsInternal = (): {
  loading: boolean;
  sunnyPools: SunnyPool[];
  sbrRewards: RewardsAndRates;
  sunnyRewards: RewardsAndRates;
  tvlUSD?: Fraction;
} => {
  const { pools } = useAllPoolsWithUserBalances();
  const landlordKey = LANDLORD_KEY;
  const { sunny, sunnyMut } = useSDK();
  const owner = sunnyMut?.provider.wallet.publicKey;

  const { parsePlot, parseFarmer } = useSaberParsers();
  const { parseVault, parsePool } = useSunnyParsers();
  const { parseQuarry, parseMiner } = useMineParsers();

  const plotInputs = useMemo(
    () =>
      pools.map(
        ({ pool }): ProgramAddressInput => ({
          type: ProgramAddressType.PLOT,
          path: [landlordKey, pool.lpToken.mintAccount],
        })
      ),
    [landlordKey, pools]
  );
  const plotKeys = useProgramAddresses(plotInputs);
  const plotsData = useParsedAccountsData(plotKeys, parsePlot);

  const farmerInputs = useMemo(
    () =>
      pools.map(({ pool }): ProgramAddressInput | null =>
        owner
          ? {
              type: ProgramAddressType.FARMER,
              path: [landlordKey, pool.lpToken.mintAccount, owner],
            }
          : null
      ),
    [landlordKey, owner, pools]
  );
  const farmerKeys = useProgramAddresses(farmerInputs);
  const farmersData = useParsedAccountsData(farmerKeys, parseFarmer);

  const poolInputs = useMemo(
    () =>
      pools.map(({ pool }): ProgramAddressInput => {
        return {
          type: ProgramAddressType.SS_POOL,
          path: [landlordKey, pool.lpToken.mintAccount],
        };
      }),
    [landlordKey, pools]
  );
  const poolKeys = useProgramAddresses(poolInputs);
  const poolsData = useParsedAccountsData(poolKeys, parsePool);

  const vaultInputs = useMemo(
    () =>
      pools.map(({ pool }): ProgramAddressInput | null =>
        owner
          ? {
              type: ProgramAddressType.SS_VAULT,
              path: [landlordKey, pool.lpToken.mintAccount, owner],
            }
          : null
      ),
    [landlordKey, owner, pools]
  );
  const vaultKeys = useProgramAddresses(vaultInputs);
  const vaultsData = useParsedAccountsData(vaultKeys, parseVault);

  const vaultFarmerInputs = useMemo(
    () =>
      zip(pools, vaultKeys).map(
        ([pool, vaultKey]): ProgramAddressInput | null =>
          pool && vaultKey
            ? {
                type: ProgramAddressType.FARMER,
                path: [landlordKey, pool.pool.lpToken.mintAccount, vaultKey],
              }
            : null
      ),
    [landlordKey, pools, vaultKeys]
  );
  const vaultFarmerKeys = useProgramAddresses(vaultFarmerInputs);
  const vaultFarmersData = useParsedAccountsData(vaultFarmerKeys, parseFarmer);

  const quarryInputs = useMemo(
    () =>
      poolsData.map((poolData): ProgramAddressInput | null =>
        poolData
          ? {
              type: ProgramAddressType.MINE_QUARRY,
              path: [
                sunny.environment.rewarder,
                poolData.accountInfo.data.farmMint,
              ],
            }
          : null
      ),
    [poolsData, sunny.environment.rewarder]
  );
  const quarryKeys = useProgramAddresses(quarryInputs);
  const quarriesData = useParsedAccountsData(quarryKeys, parseQuarry);

  const minerInputs = useMemo(
    () =>
      zip(poolsData, vaultKeys).map(
        ([
          poolData,
          vaultKey,
        ]): ProgramAddressInput<ProgramAddressType.MINE_MINER> | null =>
          poolData && vaultKey
            ? {
                type: ProgramAddressType.MINE_MINER,
                path: [
                  sunny.environment.rewarder,
                  poolData.accountInfo.data.farmMint,
                  vaultKey,
                ],
              }
            : null
      ),
    [poolsData, sunny.environment.rewarder, vaultKeys]
  );
  const minerKeys = useProgramAddresses(minerInputs);
  const minersData = useParsedAccountsData(minerKeys, parseMiner);

  const { getAllClaimableAmounts: getAllClaimableAmountsSBR } =
    useGetAllClaimableAmountsSBR({
      plotsData,
      farmersData: vaultFarmersData,
      vaultsData,
    });

  const { getAllClaimableAmounts: getAllClaimableAmountsSUNNY } =
    useGetTotalClaimableAmountSUNNY({
      quarriesData,
      minersData,
    });

  const { prices } = usePrices();
  const { exchangeMap } = useRouter();
  const { usdPerLPTokenMap } = useMemo(() => {
    const usdPerLPTokenMap: Record<string, Fraction> = {};
    Object.values(exchangeMap).forEach((pool) => {
      const currency = getMarket(pool.exchange.tokens[0]);
      const { price: currencyPrice } = prices[currency];
      const lpTotalSupply = pool.info.lpTotalSupply;
      const tvlUSD =
        (currencyPrice &&
          pool.info.reserves
            .map((r) => r.amount.asFraction)
            .reduce((acc, el) => acc.add(el))
            .multiply(currencyPrice)) ||
        null;
      if (tvlUSD) {
        const usdPerLPToken = tvlUSD.divide(lpTotalSupply);
        usdPerLPTokenMap[pool.exchange.id] = usdPerLPToken;
      }
    }, new Fraction(0));
    return { usdPerLPTokenMap };
  }, [exchangeMap, prices]);

  const plots = useMemo(() => {
    const sunnyData = zip(poolsData, quarriesData, minersData);
    const vaultsAndFarmers = zip(vaultsData, vaultFarmersData);

    return zip(plotsData, pools, farmersData, sunnyData, vaultsAndFarmers)
      .map(
        (
          [plotData, pool, farmerData, sunnyDatum, vaultAndFarmerData],
          i
        ): SunnyPool | null => {
          if (!pool || !plotData || !vaultAndFarmerData || !sunnyDatum) {
            return null;
          }
          const [poolData, quarryData, minerData] = sunnyDatum;
          if (!poolData || !quarryData) {
            return null;
          }
          const [vaultData, vaultFarmerData] = vaultAndFarmerData;

          const poolValueLockedUSD = usdPerLPTokenMap[pool.id]?.multiply(
            new TokenAmount(
              pool.pool.lpToken,
              poolData.accountInfo.data.totalLpTokenBalance
            )
          );

          // pending = non-zero claim fee + 0 share
          // hidden = 0% claim fee + 0 share
          const hasClaimFee = !(
            poolData.accountInfo.data.fees.claimFee as MegaBPS
          ).megaBps.isZero();
          const hasZeroRewards =
            quarryData.accountInfo.data.rewardsShare.isZero();
          const status = (() => {
            if (hasZeroRewards) {
              return hasClaimFee ? PoolStatus.Pending : PoolStatus.Hidden;
            }
            return PoolStatus.Active;
          })();

          return {
            index: i,
            key: plotData.accountId,
            poolID: pool.id,
            pool: pool.pool,
            plot: plotData,
            farmer: farmerData?.accountInfo.data ?? null,
            vault: vaultData,
            vaultFarmer: vaultFarmerData,
            sunnyPool: poolData,
            quarry: quarryData,
            miner: minerData,
            status,
            poolValueLockedUSD,
          };
        }
      )
      .filter((p): p is SunnyPool => !!p);
  }, [
    farmersData,
    minersData,
    plotsData,
    pools,
    poolsData,
    quarriesData,
    usdPerLPTokenMap,
    vaultFarmersData,
    vaultsData,
  ]).sort((a, b) => {
    if (!a.poolValueLockedUSD) {
      return -1;
    }
    if (!b.poolValueLockedUSD) {
      return 1;
    }
    if (a.poolValueLockedUSD.equalTo(b.poolValueLockedUSD)) {
      return a.poolID < b.poolID ? -1 : 1;
    }
    return a.poolValueLockedUSD.greaterThan(b.poolValueLockedUSD) ? -1 : 1;
  });

  const loading = useMemo(
    () =>
      plotsData.findIndex((p) => p === undefined) !== -1 ||
      farmersData.findIndex((f) => f === undefined) !== -1,
    [farmersData, plotsData]
  );

  const { sbr: sbrRewards, sunny: sunnyRewards } = useAllRewards({
    getAllClaimableAmountsSBR,
    getAllClaimableAmountsSUNNY,
  });

  const tvlUSD = useMemo(
    () =>
      plots
        .map((p) => p.poolValueLockedUSD ?? new Fraction(0))
        .reduce((acc, plot) => (plot ? acc.add(plot) : acc), new Fraction(0)),
    [plots]
  );

  return {
    loading,
    sunnyPools: plots,

    sbrRewards,
    sunnyRewards,
    tvlUSD,
  };
};

export const { Provider: SunnyPoolsProvider, useContainer: useSunnyPools } =
  createContainer(useSunnyPoolsInternal);
