import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Token } from "@saberhq/token-utils";
import React, { useState } from "react";
import { FaSort } from "react-icons/fa";

import { TokenIcon } from "../TokenIcon";
import { SelectTokenModal } from "./SelectTokenModal";

interface IProps {
  tokens?: readonly Token[];
  token: Token | null;
  onChange?: (token: Token) => void;
}

export const TokenDropdown: React.FC<IProps> = ({
  tokens,
  token,
  onChange,
}: IProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  return (
    <>
      {onChange && (
        <SelectTokenModal
          tokens={tokens}
          selectedToken={token ?? undefined}
          onSelect={(t) => {
            onChange(t);
            setShowDropdown(false);
          }}
          isOpen={showDropdown}
          onDismiss={() => setShowDropdown(false)}
        />
      )}
      <Selector
        isStatic={onChange === undefined}
        onClick={onChange ? () => setShowDropdown(true) : undefined}
        tabIndex={onChange ? 0 : -1}
      >
        {token ? (
          <TokenIcon
            css={css`
              width: 32px;
              height: 32px;
            `}
            src={token.icon}
            alt={`Icon of token ${token.symbol}`}
          />
        ) : (
          <div />
        )}
        <TokenInfo>
          <TokenSymbol>
            {token?.symbol === "SLP" ? "Saber LP" : token?.symbol}
          </TokenSymbol>
          <TokenName>{token?.name.replace(" Saber LP", "")}</TokenName>
        </TokenInfo>
        {onChange && (
          <SortIcon>
            <FaSort />
          </SortIcon>
        )}
      </Selector>
    </>
  );
};

const TokenInfo = styled.div`
  width: 100%;
  max-width: 100%;
`;

const TokenSymbol = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16.71px;
  color: ${({ theme }) => theme.colors.text.bold};
`;

const TokenName = styled.div`
  font-size: 12px;
  line-height: 14.32px;
  margin-top: 1px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.colors.text.default};
`;

const Selector = styled.button<{ isStatic?: boolean }>`
  transition: 0.1s ease;
  user-select: none;
  background: ${({ theme }) => theme.colors.cryptoselector.base.default};
  ${({ isStatic, theme }) =>
    isStatic !== true &&
    css`
      cursor: pointer;
      &:hover {
        background: ${theme.colors.cryptoselector.base.hover};
      }
    `}
  height: 48px;
  border: 0;
  display: flex;
  gap: 8px;
  // grid-template-columns: 32px calc(100% - 32px - 8px - 8px - 8px) 8px;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  padding: 8px 9px 8px 8px;
  // min-width: 80px;
  // flex-grow: 1;
  border-radius: 8px;
  text-align: left;
  & > svg {
    color: ${({ theme }) => theme.colors.text.muted};
  }
`;

const SortIcon = styled.div`
  width: 16px;
  height: 20px;
`;
