import { useEffect, useState } from "react";

export const calculateTooltip = (
  args: ClaimableTooltipArgs
): string[] | null => {
  const hasRewardsClaimable = !!(args.totalSBR || args.totalSUNNY);
  const isEarningRewards = !!(args.rateSBR || args.rateSUNNY);

  const totalSUNNY = args.totalSUNNY?.();
  const totalSBR = args.totalSBR?.();

  const isDust = !(
    (totalSUNNY && totalSUNNY > 0.0864) ||
    (totalSBR && totalSBR > 0.0864)
  );

  if (!hasRewardsClaimable || isDust) {
    // Don't let the user claim dust
    if (isEarningRewards) {
      // Sometimes, the user is earning but has 0.000000 yield
      return ["Wait for a bit more rewards before claiming"];
    } else {
      return ["Only old rewards show up here"];
    }
  }
  return null;
};

export interface ClaimableTooltipArgs {
  totalSBR?: () => number | null | undefined;
  totalSUNNY?: () => number | null | undefined;
  rateSBR?: number | null;
  rateSUNNY?: number | null;
}

export const useAmountClaimableTooltip = (
  args: ClaimableTooltipArgs
): string[] | null => {
  const [claimableTooltip, setClaimableTooltip] = useState<string[] | null>(
    calculateTooltip(args)
  );

  // The yield can change the tooltip status, but the button would still be disabled
  useEffect(() => {
    const interval = setInterval(() => {
      try {
        const newTooltip = calculateTooltip(args);

        if (claimableTooltip !== newTooltip) {
          setClaimableTooltip(newTooltip);
        }
      } catch (e) {
        console.error(e);
      }
    }, 200);
    return () => clearInterval(interval);
  }, [args, claimableTooltip]);

  return claimableTooltip === null ? null : claimableTooltip;
};
