import styled from "@emotion/styled";
import { useConnectedWallet, useConnectionContext } from "@saberhq/use-solana";
import copy from "copy-to-clipboard";
import React from "react";
import { FaClipboard, FaGlobeAsia, FaPowerOff } from "react-icons/fa";

import { useConfig } from "../../../../contexts/config";
import { notify } from "../../../../utils/notifications";
import type { IProps as IModalProps } from "../../../common/Modal";
import { Modal } from "../../../common/Modal";
import { ModalResults } from "../../../common/Modal/ModalResults";
// import { useUserLockup } from "../../../pages/tools/LockupView/useUserLockup";

type IProps = Omit<IModalProps, "children" | "title"> & {
  mode: "change-network" | "settings";
  setMode: (network: "change-network" | "settings") => void;
};

export const WalletSettingsModal: React.FC<IProps> = ({
  mode,
  setMode,
  ...modalProps
}: IProps) => {
  const wallet = useConnectedWallet();
  const { network: selectedNetwork, setNetwork } = useConnectionContext();
  // const { release } = useUserLockup();
  const { environments } = useConfig();

  const onDismiss = () => {
    setMode("settings");
    modalProps.onDismiss();
  };

  if (mode === "change-network") {
    return (
      <Modal title="Change Network" {...modalProps} onDismiss={onDismiss}>
        <ModalResults
          results={(["mainnet-beta", "devnet"] as const).map((network) => {
            const env = environments[network];
            return {
              key: network,
              content: (
                <Option>
                  <FaGlobeAsia />
                  <span>{env.name}</span>
                </Option>
              ),
              onClick: () => {
                setNetwork(network);
                setMode("settings");
                modalProps.onDismiss();
              },
              selected: network === selectedNetwork,
            };
          })}
        />
      </Modal>
    );
  }

  const results = [
    {
      key: "change-network",
      content: (
        <Option>
          <FaGlobeAsia />
          <span>Change network</span>
        </Option>
      ),
      onClick: () => {
        setMode("change-network");
      },
    },
    {
      key: "copy-address",
      content: (
        <Option>
          <FaClipboard />
          <span>Copy Address</span>
        </Option>
      ),
      onClick: () => {
        if (wallet) {
          copy(wallet.publicKey.toString());
          notify({
            message: `Copied address to clipboard.`,
          });
        }
        modalProps.onDismiss();
      },
    },
    {
      key: "disconnect",
      content: (
        <Option>
          <FaPowerOff />
          <span>Disconnect wallet</span>
        </Option>
      ),
      onClick: () => {
        wallet?.disconnect();
        modalProps.onDismiss();
      },
    },
  ];

  return (
    <Modal title="Wallet Settings" {...modalProps} onDismiss={onDismiss}>
      <ModalResults results={results} />
    </Modal>
  );
};

const Option = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    height: 16px;
    width: 16px;
    margin-right: 12px;
  }
`;
