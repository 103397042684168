import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { breakpoints } from "../../../theme/breakpoints";
import { Digits } from "../../common/Digits";
import { SlowRotatingSunLogo } from "../../common/LoadingSpinner";
import {
  HoverTooltip,
  HoverTooltipDefaultTopOffset,
} from "../HomePage/HoverTooltip";

export interface IRewardsNumbersProps {
  amountSBR?: number;
  tooltipSBR?: string[] | null;
  amountSUNNY?: number | null; // null means not yet enabled
  tooltipSUNNY?: string[] | null;
}

export const RewardsNumbers: React.FC<IRewardsNumbersProps> = ({
  amountSBR,
  tooltipSBR,
  amountSUNNY,
  tooltipSUNNY,
}: IRewardsNumbersProps) => {
  return (
    <RewardsNumbersWrapper>
      <RewardsNumbersReward
        css={css`
          z-index: 5;
        `}
      >
        <SpacerWrapper>
          <HoverTooltip message={tooltipSBR}>
            <AnimatedGaryShell>
              <AnimatedGaryShimmer />
              <GarysText>
                <Digits value={amountSBR} />
              </GarysText>
            </AnimatedGaryShell>{" "}
          </HoverTooltip>
        </SpacerWrapper>
        {/* SUNNYTODO: This icon is just hacked in ... */}
        <img
          src="https://cdn.jsdelivr.net/gh/saber-hq/spl-token-icons@master/icons/101/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1.svg"
          alt="Icon of token SBR"
        ></img>
        <CoinName>SBR</CoinName>
      </RewardsNumbersReward>
      <RewardsNumbersReward>
        <SpacerWrapper>
          <HoverTooltip message={tooltipSUNNY}>
            <AnimatedGaryShell>
              <AnimatedGaryShimmer />
              <GarysText>
                <Digits value={amountSUNNY} />
              </GarysText>
            </AnimatedGaryShell>{" "}
          </HoverTooltip>
        </SpacerWrapper>
        <SlowRotatingSunLogo />
        <CoinName>SUNNY</CoinName>
      </RewardsNumbersReward>

      {/* <RewardsNumbersReward>
        <USDValue>≈ $0.00</USDValue>
      </RewardsNumbersReward> */}
    </RewardsNumbersWrapper>
  );
};

const RewardsNumbersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const RewardsNumbersReward = styled.div`
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  z-index: 4;
  img,
  svg {
    height: 22px;
    width: 22px;
    min-width: 22px;
    margin-right: 7px;
    color: #e29034;
  }
  .HoverTooltipMessageWrapper {
    top: ${HoverTooltipDefaultTopOffset - 8}px;
  }
`;

// 🐌
const AnimatedGaryShell = styled.span`
  position: relative;
  background: #eee;
  border-radius: 8px;
  padding: 12px 16px;
  overflow: hidden;
  display: block;
  line-height: 1.2;
`;
const SpacerWrapper = styled.span`
  margin-right: 14px;
  ${breakpoints.mobile} {
    margin-right: 9px;
  }
`;
const AnimatedGaryShimmer = styled.span`
  position: absolute;
  left: -200px;
  right: 400px;
  top: 0;
  bottom: 0;
  width: 200px;
  background-image: linear-gradient(
    58deg,
    rgba(238, 238, 238, 0) 25%,
    rgba(255, 255, 255, 0.5) 46%,
    rgba(255, 255, 255, 0.5) 54%,
    rgba(238, 238, 238, 0) 75%
  );

  background-repeat: no-repeat;
  pointer-events: none;

  z-index: 0;
  animation: 1.8s linear 0s infinite running gary;
  @keyframes gary {
    from {
      transform: translateX(-200%);
      //   background-position: -100% 0px;
    }
    to {
      transform: translateX(200%);

      //   background-position: 100% 0px;
    }
  }
`;
const GarysText = styled.span`
  position: relative;
  z-index: 2;
  color: #bbb;
  font-weight: 600;
`;

// const USDValue = styled.span`
//   font-weight: 300;
//   color: #666;
//   display: flex;
//   padding: 8px 16px;
// `;

const CoinName = styled.span`
  ${breakpoints.tinymobile} {
    font-size: 18px;
  }
`;
