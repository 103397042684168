import { useMemo } from "react";

import { usePlot } from "../../../contexts/plot";
import { useSunnyPools } from "../../../contexts/sunnyPools";
import { useClaimableAmounts } from "../../../contexts/sunnyPools/calculateClaimableAmounts";
import { formatDisplayWithSoftLimit } from "../../../utils/format";
import { RewardsNumbers } from "../HomePage/RewardsNumbers";

export const PoolRewards: React.FC = () => {
  const { sbrRewards, sunnyRewards } = useSunnyPools();
  const { sunnyPool } = usePlot();

  const sbrAmounts = useClaimableAmounts(sbrRewards.amounts);
  const sunnyAmounts = useClaimableAmounts(sunnyRewards.amounts);

  const { claimableSBR, dailySBR } = useMemo(() => {
    return {
      claimableSBR: sunnyPool ? sbrAmounts?.pools[sunnyPool.index] : undefined,
      dailySBR:
        sunnyPool && sbrRewards.rates
          ? (sbrRewards.rates.pools[sunnyPool.index] ?? 0) * 86_400
          : undefined,
    };
  }, [sbrAmounts?.pools, sbrRewards.rates, sunnyPool]);

  const { claimableSUNNY, dailySUNNY } = useMemo(() => {
    return {
      claimableSUNNY: sunnyPool
        ? sunnyAmounts?.pools[sunnyPool.index]
        : undefined,
      dailySUNNY:
        sunnyPool && sunnyRewards.rates
          ? (sunnyRewards.rates.pools[sunnyPool.index] ?? 0) * 86_400
          : undefined,
    };
  }, [sunnyAmounts?.pools, sunnyPool, sunnyRewards.rates]);

  return (
    <RewardsNumbers
      amountSBR={claimableSBR ?? undefined}
      tooltipSBR={
        claimableSBR === undefined || dailySBR === undefined
          ? ["Earn SBR after depositing", "Saber LP into Sunny"]
          : [formatDisplayWithSoftLimit(dailySBR ?? 0, 6, 6) + " SBR/day"]
      }
      amountSUNNY={claimableSUNNY}
      tooltipSUNNY={
        claimableSUNNY === undefined || typeof dailySUNNY !== "number" // 0 is falsy but we still display 0.000000 SUNNY/day
          ? ["Earn SUNNY after depositing", "Saber LP into Sunny"]
          : [formatDisplayWithSoftLimit(dailySUNNY, 6, 6) + " SUNNY/day"]
      }
    />
  );
};
