import type { Fraction } from "@saberhq/token-utils";
import { useMemo } from "react";

import { useStableSwap } from "../useStableSwap";
import type { PlotStats } from "./calculatePlotStats";

interface PlotPriceStats {
  tvl: Fraction | null;
  tvlUSD: Fraction | null;
}

/**
 * Stats that are derived with plot price
 * @param param0
 * @returns
 */
export const usePlotPriceStats = ({
  totalDeposits,
}: Pick<PlotStats, "totalDeposits">): PlotPriceStats => {
  const { currencyPriceUSD, virtualPrice } = useStableSwap();

  const tvl = useMemo(() => {
    if (!virtualPrice || !totalDeposits) {
      return null;
    }
    return virtualPrice.multiply(totalDeposits.asFraction);
  }, [totalDeposits, virtualPrice]);
  const tvlUSD = useMemo(() => {
    if (!tvl || !currencyPriceUSD) {
      return null;
    }
    return tvl.multiply(currencyPriceUSD);
  }, [currencyPriceUSD, tvl]);

  return { tvl, tvlUSD };
};
