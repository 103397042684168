import type { AccountParser } from "@saberhq/sail";
import type { KeyedAccountInfo } from "@solana/web3.js";
import type { Farmer, Plot } from "@sunnyaggregator/sunny-sdk";
import { useCallback } from "react";

import { useSDK } from "../../contexts/sdk";

interface SaberParsers {
  parsePlot: AccountParser<Plot>;
  parseFarmer: AccountParser<Farmer>;
}

export const useSaberParsers = (): SaberParsers => {
  const { sunny } = useSDK();
  const farmProgram = sunny.programs.Farm;

  const parsePlot = useCallback(
    (d: KeyedAccountInfo) =>
      farmProgram.coder.accounts.decode<Plot>("Plot", d.accountInfo.data),
    [farmProgram.coder.accounts]
  );

  const parseFarmer = useCallback(
    (d: KeyedAccountInfo) =>
      farmProgram.coder.accounts.decode<Farmer>("Farmer", d.accountInfo.data),
    [farmProgram.coder.accounts]
  );

  return { parsePlot, parseFarmer };
};
