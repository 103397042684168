import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { TokenAmount } from "@saberhq/token-utils";
import React, { useMemo, useState } from "react";
import invariant from "tiny-invariant";

import { usePlot } from "../../../contexts/plot";
import { getMarket } from "../../../utils/currencies";
import { useStableSwap } from "../../../utils/useStableSwap";
import { AsyncButton } from "../../common/AsyncButton";
import { TokenAmountSelector } from "../../common/TokenAmountSelector";

export const WithdrawWidget: React.FC = () => {
  const { exchange } = useStableSwap();
  const [withdrawAmountStr, setDepositAmountStr] = useState<string>("");

  const lpToken = exchange?.lpToken;
  const { withdraw, stakedAmount } = usePlot();

  const withdrawAmount = useMemo(() => {
    if (!lpToken) {
      return null;
    }
    try {
      return TokenAmount.parse(lpToken, withdrawAmountStr);
    } catch (e) {
      // ignore
    }
  }, [withdrawAmountStr, lpToken]);

  const withdrawDisabledReason =
    !withdrawAmount || withdrawAmount?.equalTo(0)
      ? "Enter amount to withdraw"
      : null;
  const tokenA = exchange?.tokens[0];

  return (
    <DepositWidgetWrapper>
      <InnerSection>
        <TokenAmountSelector
          selectedValue={lpToken ?? null}
          inputValue={withdrawAmountStr}
          inputOnChange={setDepositAmountStr}
          currentAmount={{
            amount: stakedAmount ?? undefined,
            label: "Deposited",
            allowSelect: true,
          }}
          // onSelect={() => {
          //   console.log(
          //     "SUNNYTODO: Allow users to pick from withdrawable coins and SLP, but not other coins that would require a swap."
          //   );
          // }}
          currency={tokenA ? getMarket(tokenA) : undefined}
        />
      </InnerSection>
      <DepositButtonWrapper>
        <AsyncButton
          size="small"
          disabled={!!withdrawDisabledReason}
          onClick={async () => {
            invariant(withdrawAmount, "no withdraw amount");
            await withdraw(withdrawAmount);
            setDepositAmountStr("");
          }}
          css={css`
            width: 100%;
          `}
        >
          {withdrawDisabledReason ?? "Withdraw Saber LP"}
        </AsyncButton>
      </DepositButtonWrapper>
    </DepositWidgetWrapper>
  );
};

const DepositWidgetWrapper = styled.div`
  background: blue;
  padding: 12px;
  background: #f8f8f9;
  border-radius: 12px;
`;
const DepositButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerSection = styled.div``;
