import type { ParsedAccountDatum } from "@saberhq/sail";
import { TokenAmount } from "@saberhq/token-utils";
import * as Sentry from "@sentry/react";
import type { MinerData, QuarryData } from "@sunnyaggregator/sunny-sdk";
import { Payroll, SUNNY_ADDRESS } from "@sunnyaggregator/sunny-sdk";
import BN from "bn.js";
import { zip } from "lodash";
import { useCallback, useMemo } from "react";

import { useToken } from "../../utils/farming/useToken";
import type { GetAllClaimableAmounts } from "./types";

export const useGetTotalClaimableAmountSUNNY = ({
  quarriesData,
  minersData,
}: {
  quarriesData: ParsedAccountDatum<QuarryData>[];
  minersData: ParsedAccountDatum<MinerData>[];
}): {
  getAllClaimableAmounts: GetAllClaimableAmounts;
} => {
  const { token: sunny } = useToken(SUNNY_ADDRESS);

  const payrolls = useMemo(() => {
    return zip(quarriesData, minersData).map(([quarry, miner]) => {
      const quarryData = quarry?.accountInfo.data;
      const minerData = miner?.accountInfo.data;
      const payroll = quarryData
        ? new Payroll(
            quarryData.famineTs,
            quarryData.lastUpdateTs,
            quarryData.dailyRewardsRate.div(new BN(86_400)),
            quarryData.rewardsPerTokenStored,
            new BN(quarryData.tokenMintDecimals),
            quarryData.totalTokensDeposited
          )
        : null;
      return { payroll, minerData };
    });
  }, [minersData, quarriesData]);

  const getAllClaimableAmounts = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    (additionalMilliseconds: number = 0) => {
      if (!sunny) {
        return null;
      }

      const now = new BN(
        Math.floor((new Date().getTime() + additionalMilliseconds) / 1000)
      );
      const allClaimable = payrolls.map(({ payroll, minerData }) => {
        if (!payroll || !minerData) {
          return null;
        }
        const wages = payroll.calculateWagesEarned(
          now,
          minerData.balance,
          minerData.wagesPerTokenPaid,
          minerData.wagesEarned
        );
        try {
          return new TokenAmount(sunny, wages);
        } catch (e) {
          console.error(`Could not parse claimable amount`, e);
          Sentry.captureException(e as Error, {
            tags: {
              action: "plot.getClaimableAmount",
            },
            extra: {
              payrollFamineTs: payroll.famineTs.toString(),
              payrollLastCheckpointTs: payroll.lastCheckpointTs.toString(),
              payrollRewardsRatePerSecond:
                payroll.rewardsRatePerSecond.toString(),
              payrollRewardsPerTokenStored:
                payroll.rewardsPerTokenStored.toString(),
              payrollTokenDecimals: payroll.tokenDecimals.toString(),
              payrollTotalTokensDeposited:
                payroll.totalTokensDeposited.toString(),

              currentTS: now.toString(),
              tokensDeposited: minerData.balance.toString(),
              wagesPerTokenPaid: minerData.wagesPerTokenPaid.toString(),
              wagesEarned: minerData.wagesEarned.toString(),
              wages: wages.toString(),
            },
          });
          return null;
        }
      });

      const allAmounts = allClaimable.filter((t): t is TokenAmount => !!t);
      const sum =
        allAmounts.length > 0
          ? allAmounts.reduce((acc, el) => acc.add(el))
          : null;
      return {
        sum,
        allClaimable,
      };
    },
    [payrolls, sunny]
  );

  return {
    getAllClaimableAmounts,
  };
};
