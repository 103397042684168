import type { Fraction } from "@saberhq/token-utils";
import { useMemo } from "react";

import { useStableSwap } from "../useStableSwap";
import type { PlotStats } from "./calculatePlotStats";

interface UserPlotShare {
  stakeValue: Fraction | null;
  stakeValueUSD: Fraction | null;
}

export const useUserPlotShare = ({
  stakedAmount,
}: Pick<PlotStats, "stakedAmount">): UserPlotShare => {
  const { currencyPriceUSD, virtualPrice } = useStableSwap();

  const stakeValue = useMemo(() => {
    if (!virtualPrice || !stakedAmount) {
      return null;
    }
    return virtualPrice.multiply(stakedAmount.asFraction);
  }, [stakedAmount, virtualPrice]);
  const stakeValueUSD = useMemo(() => {
    if (!stakeValue || !currencyPriceUSD) {
      return null;
    }
    return stakeValue.multiply(currencyPriceUSD);
  }, [currencyPriceUSD, stakeValue]);

  return { stakeValue, stakeValueUSD };
};
