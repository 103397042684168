import type { HandleTXsResponse } from "@saberhq/sail";
import { TOKEN_ACCOUNT_PARSER, useParsedAccountsData } from "@saberhq/sail";
import type { Percent, Token } from "@saberhq/token-utils";
import { TokenAmount } from "@saberhq/token-utils";
import * as Sentry from "@sentry/react";
import type { PublicKey } from "@solana/web3.js";
import { Keypair } from "@solana/web3.js";
import type { Plot } from "@sunnyaggregator/sunny-sdk";
import { CREATOR_KEY, LANDLORD_KEY } from "@sunnyaggregator/sunny-sdk";
import { useCallback, useMemo } from "react";
import invariant from "tiny-invariant";
import { createContainer } from "unstated-next";

import { calculatePlotStats } from "../../utils/farming/calculatePlotStats";
import { useGovernanceToken } from "../../utils/farming/useGovernanceToken";
import { useLandlordState } from "../../utils/farming/useLandlordState";
import { useSunnyParsers } from "../../utils/farming/useSunnyParsers";
import { useSDK } from "../sdk";
import type { SunnyPool } from "../sunnyPools";
import { useSunnyPools } from "../sunnyPools";
import type { UseSunnyMine } from "./sunny";
import { useSunnyMine } from "./sunny";

export interface UsePlot {
  sunnyPool?: SunnyPool;
  /**
   * Staked token
   */
  token: Token | null;
  /**
   * Loading
   */
  loading: boolean;
  /**
   * Number of rewards tokens per day.
   */
  rewardsPerDay: TokenAmount | null;
  /**
   * Number of rewards tokens per day for the current user.
   */
  // userRewardsPerDay: TokenAmount | null;
  /**
   * Key of the plot
   */
  plotKey?: PublicKey;
  /**
   * Plot info
   */
  plot: Plot | null;
  /**
   * Rewards share
   */
  rewardsShare: Percent | null;

  sunny: UseSunnyMine;

  totalDeposits: TokenAmount | null;

  /**
   * Number of tokens staked.
   */
  stakedAmount: TokenAmount | null;
  /**
   * Stakes the user into the plot.
   */
  stake: (amount: TokenAmount) => Promise<void>;
  /**
   * Withdraws a number of LP tokens from the plot.
   */
  withdraw: (amount: TokenAmount) => Promise<void>;
  /**
   * Claims rewards.
   */
  claim: () => Promise<HandleTXsResponse>;

  rewards: {
    dailySBR?: number;
    dailySUNNY?: number;
  };
}

export const usePlotInternal = ({
  plot,
}: { plot?: SunnyPool } = {}): UsePlot => {
  const token = plot?.pool.lpToken;
  const stableSwap = plot?.pool;
  const plotData = plot?.plot;

  const { sunnyMut, handleTx, handleTxs } = useSDK();
  const { state: landlord } = useLandlordState();
  const owner = sunnyMut?.provider.wallet.publicKey;

  const farmer = plot?.farmer ?? null;
  const vault = plot?.vault;

  const { farmerTokenVault } = useMemo(
    () => ({
      farmerTokenVault: [farmer?.tokenVaultKey],
    }),
    [farmer]
  );
  const [farmerBalanceData] = useParsedAccountsData(
    farmerTokenVault,
    TOKEN_ACCOUNT_PARSER
  );

  const { token: sbr } = useGovernanceToken();
  const { rewardsShare, totalDeposits, rewardsPerDay } = useMemo(() => {
    return calculatePlotStats({
      landlord,
      plot: plotData?.accountInfo.data,
      farmer,
      token,
      sbr,
      farmerBalance: farmerBalanceData?.accountInfo.data.amount,
    });
  }, [
    farmer,
    farmerBalanceData?.accountInfo.data.amount,
    landlord,
    plotData?.accountInfo.data,
    sbr,
    token,
  ]);

  const stakedAmount = useMemo(() => {
    return vault && token
      ? new TokenAmount(token, vault.accountInfo.data.stakedBalance)
      : null;
  }, [token, vault]);

  const { parsePool } = useSunnyParsers();

  const sunny = useSunnyMine({
    quarry: plot?.quarry,
    miner: plot?.miner,
  });

  const ensureVaultExists = useCallback(async () => {
    invariant(sunnyMut, "sdk not connected");
    invariant(token, "token");
    invariant(stableSwap, "swap");

    const connection = sunnyMut.provider.connection;

    const pool = await sunnyMut.ssFarm.findPoolAddress({
      creator: CREATOR_KEY,
      lpMint: token.mintAccount,
    });

    // create pool if not exists
    const poolData = await connection.getAccountInfo(pool);
    let farmMint: PublicKey;
    if (!poolData) {
      const farmMintKP = Keypair.generate();
      const { tx: newPoolTX } = await sunnyMut.ssFarm.newPool({
        creator: CREATOR_KEY,
        lpMint: stableSwap.lpToken.mintAccount,
        farmMintKP,
      });
      farmMint = farmMintKP.publicKey;
      await handleTx(newPoolTX, "Create Pool");
    } else {
      farmMint = parsePool({ accountId: pool, accountInfo: poolData }).farmMint;
    }

    // create vault if not exists
    const vault = await sunnyMut.ssFarm.findVaultAddress({
      pool,
    });
    if (!(await connection.getAccountInfo(vault))) {
      const { tx: newVaultTX } = await sunnyMut.ssFarm.newVault({
        pool: {
          key: pool,
          data: {
            farmMint,
            lpMint: token.mintAccount,
            landlord: LANDLORD_KEY,
          },
        },
      });
      // TODO(surya): this should be done atomically with the deposit step
      await handleTx(newVaultTX, "Create Vault");
    }
  }, [handleTx, parsePool, stableSwap, sunnyMut, token]);

  /**
   * Polls and waits for the vault to exist.
   *
   * This is a hack to fix some race conditions where the vault isn't created yet but the app tries to deposit
   */
  const pollForVaultExistence = async (
    loadVaultArg: { vaultKey: PublicKey },
    timeoutMilliseconds = 8000
  ): Promise<void> => {
    if (sunnyMut === null) {
      return;
    }
    const start = Date.now();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let latestError: Error | string = "... no error message";
    let hasFailed = false;
    while (Date.now() - start < timeoutMilliseconds) {
      console.log(
        `Looking for vault existence: ${loadVaultArg.vaultKey.toString()}`
      );

      try {
        await sunnyMut.ssFarm.loadVault(loadVaultArg);
        await sunnyMut.ssFarm.loadVault(loadVaultArg);
        if (hasFailed) {
          await new Promise((r) => setTimeout(r, 1200)); // Sleep just in case, because we failed in the past
        }
        console.log("Vault with key found!");
        return;
      } catch (e) {
        hasFailed = true;
        console.log("Vault with key not found... sleeping for 500ms");
        await new Promise((r) => setTimeout(r, 500));
        latestError = e as Error;
      }
    }
    throw Error(
      `Timed out when waiting for vault to exist: ${loadVaultArg.vaultKey.toString()}. Error message: ${latestError.toString()}`
    );
  };

  const stake = async (amount: TokenAmount) => {
    let step = 0;
    try {
      await ensureVaultExists();
      invariant(sunnyMut, "sdk not connected");
      invariant(token, "token");

      step = 0;

      const pool = await sunnyMut.ssFarm.findPoolAddress({
        creator: CREATOR_KEY,
        lpMint: token.mintAccount,
      });
      step = 1;

      const vault = await sunnyMut.ssFarm.findVaultAddress({
        pool,
      });
      step = 2;

      await pollForVaultExistence({ vaultKey: vault });
      step = 3;

      const theVault = await sunnyMut.ssFarm.loadVault({ vaultKey: vault });
      step = 4;

      const depositTX = await theVault.deposit(amount);
      step = 5;

      await handleTx(
        depositTX,
        `Stake ${amount.format()} ${amount.token.symbol}`
      );
    } catch (e) {
      console.log("stake error at step", step, e);
      Sentry.captureException(e, {
        extra: {
          step,
        },
      });

      throw e;
    }
  };

  const withdraw = async (amount: TokenAmount) => {
    invariant(sunnyMut, "sdk not connected");
    invariant(token, "token");

    const pool = await sunnyMut.ssFarm.findPoolAddress({
      creator: CREATOR_KEY,
      lpMint: token.mintAccount,
    });
    const vault = await sunnyMut.ssFarm.findVaultAddress({
      pool,
    });
    const theVault = await sunnyMut.ssFarm.loadVault({ vaultKey: vault });
    const withdrawTX = await theVault.withdraw(amount);
    await handleTx(
      withdrawTX,
      `Withdraw ${amount.format()} ${amount.token.symbol}`
    );
  };

  const claim = async (): Promise<HandleTXsResponse> => {
    invariant(sunnyMut, "sdk not connected");
    invariant(token, "token");
    const pool = await sunnyMut.ssFarm.findPoolAddress({
      creator: CREATOR_KEY,
      lpMint: token.mintAccount,
    });
    const vault = await sunnyMut.ssFarm.findVaultAddress({
      pool,
    });
    const theVault = await sunnyMut.ssFarm.loadVault({ vaultKey: vault });
    return await handleTxs(
      [await theVault.claimMineRewards(), await theVault.claimFarmRewards()],
      "Claim"
    );
  };

  const anyLoading =
    plotData === undefined ||
    (owner ? farmer === undefined : false) ||
    farmerBalanceData === undefined ||
    vault === undefined;

  const { sbrRewards, sunnyRewards } = useSunnyPools();
  const sunnyPool = plot;
  const rewards = useMemo(() => {
    return {
      dailySBR:
        sunnyPool && sbrRewards.rates
          ? (sbrRewards.rates.pools[sunnyPool.index] ?? 0) * 86_400
          : undefined,
      dailySUNNY:
        sunnyPool && sunnyRewards.rates
          ? (sunnyRewards.rates.pools[sunnyPool.index] ?? 0) * 86_400
          : undefined,
    };
  }, [sbrRewards.rates, sunnyPool, sunnyRewards.rates]);

  return {
    sunnyPool,
    token: token ?? null,
    rewardsPerDay,
    // userRewardsPerDay,
    loading: anyLoading,
    plotKey: plotData?.accountId,
    plot: plotData?.accountInfo.data ?? null,
    rewardsShare,
    totalDeposits,
    // actions
    stakedAmount,
    stake,
    withdraw,
    claim,
    sunny,
    rewards,
  };
};

export const { useContainer: usePlot, Provider: PlotProvider } =
  createContainer(usePlotInternal);
