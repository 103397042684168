import type { AccountParser } from "@saberhq/sail";
import type { KeyedAccountInfo } from "@solana/web3.js";
import type { PoolData, VaultData } from "@sunnyaggregator/sunny-sdk";
import { useCallback } from "react";

import { useSDK } from "../../contexts/sdk";

interface SunnyParsers {
  parsePool: AccountParser<PoolData>;
  parseVault: AccountParser<VaultData>;
}

export const useSunnyParsers = (): SunnyParsers => {
  const { sunny } = useSDK();
  const program = sunny.programs.SunnySaberFarm;

  const parsePool = useCallback(
    (d: KeyedAccountInfo) =>
      program.coder.accounts.decode<PoolData>("Pool", d.accountInfo.data),
    [program.coder.accounts]
  );

  const parseVault = useCallback(
    (d: KeyedAccountInfo) =>
      program.coder.accounts.decode<VaultData>("Vault", d.accountInfo.data),
    [program.coder.accounts]
  );

  return { parsePool, parseVault };
};
