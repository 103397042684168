import styled from "@emotion/styled";
import type { PropsWithChildren } from "react";
import React from "react";

type TooltipProps = PropsWithChildren<{
  message: string[] | string | null | undefined;
}>;

export const HoverTooltipDefaultTopOffset = 50;

export const HoverTooltip: React.FC<TooltipProps> = ({
  message,
  children,
}: TooltipProps) => {
  return (
    <TooltipContainer>
      <Hoverable>{children}</Hoverable>
      {message === null || message === undefined ? null : (
        <MessageWrapper className="HoverTooltipMessageWrapper">
          <Message>
            {Array.isArray(message)
              ? message.map((message, index) => (
                  <span key={index}>
                    {message} <br />
                  </span>
                ))
              : message}
          </Message>
        </MessageWrapper>
      )}
    </TooltipContainer>
  );
};

const Message = styled.div`
  // display: none;
  transform: translateX(-50%);
  opacity: 0;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.18);
  background: hsl(45, 100%, 65%);
  border-radius: 4px;
  padding: 14px 18px;
  transition: opacity 0.25s ease-in-out;
  font-size: 13px;
  // width: 260px;
  span {
    white-space: nowrap;
  }
`;

const MessageWrapper = styled.div`
  position: absolute;
  top: ${HoverTooltipDefaultTopOffset}px;
  left: 50%;
  transform: translateY(-6px);
  transition: transform 0.25s ease-in-out;
  color: #000;
  pointer-events: none;
  text-align: center;
`;
const Hoverable = styled.div``;

const TooltipContainer = styled.div`
  ${Hoverable}:hover + ${MessageWrapper} {
    transform: translateY(0px);
  }
  ${Hoverable}:hover + ${MessageWrapper} ${Message} {
    opacity: 1;
  }
  ${Message} {
    user-select: none;
    pointer-events: none;
  }
  position: relative;
  z-index: 3;
`;
