import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { ParsedAccountDatum, ParsedAccountInfo } from "@saberhq/sail";
import { useAccountData } from "@saberhq/sail";
import {
  deserializeAccount,
  Fraction,
  TokenAmount,
} from "@saberhq/token-utils";
import type { Farmer, PoolData, VaultData } from "@sunnyaggregator/sunny-sdk";
import React, { useMemo, useRef, useState } from "react";
import { FaExclamationTriangle } from "react-icons/fa";

import type { SunnyPool } from "../../../contexts/sunnyPools";
import { PoolStatus } from "../../../contexts/sunnyPools";
import { breakpoints } from "../../../theme/breakpoints";
import { CurrencyMarket } from "../../../utils/currencies";
import type { PlotStats } from "../../../utils/farming/calculatePlotStats";
import { usePlotPriceStats } from "../../../utils/farming/usePlotPriceStats";
import { useUserPlotShare } from "../../../utils/farming/useUserPlotShare";
import {
  formatCurrencySmart,
  formatDisplayWithSoftLimit,
  fractionToFloat,
} from "../../../utils/format";
import type { Pool } from "../../../utils/useEnvironment";
import { useStableSwap } from "../../../utils/useStableSwap";
import { useUserAssociatedTokenAccounts } from "../../../utils/useUserAssociatedTokenAccounts";
import { PoolIcon } from "../../common/PoolIcon";
import { SaberSubPage } from "../VaultsSub/SaberSubPage";
import type { IScalableStats } from "../VaultsSub/SubPageCommon";
import {
  ScalableStatData,
  ScalableStatTitle,
} from "../VaultsSub/SubPageCommon";
import { HoverTooltip } from "./HoverTooltip";
import { useSunnyPoolAPY } from "./useSunnyPoolAPY";

interface Props {
  plot: SunnyPool;
  pool: Pool;
  farmer?: Farmer;
  vault?: ParsedAccountDatum<VaultData>;
  stats: PlotStats;
  sunnyPool: ParsedAccountInfo<PoolData>;
  totalPlotCount: number;
}

export const FarmAccordionMain: React.FC<Props> = ({
  pool,
  farmer,
  plot,
  stats,
  vault,
  sunnyPool,
  totalPlotCount,
}: Props) => {
  // Too laggy....
  // So don't even render any elements until the pool has been expanded.
  // Once expanded, just keep all react elements alive (even if re-collapsed)
  // so that inputs are preserved
  const [hasBeenLoaded, setHasbeenLoaded] = useState(totalPlotCount === 1);

  const [isOpen, setOpen] = useState(totalPlotCount === 1);
  const reFocusButtonRef = useRef<HTMLButtonElement>(null);

  // user ATA of LP
  const [lpATA] = useUserAssociatedTokenAccounts([pool.lpToken]);

  const { currency } = useStableSwap();
  const { data: farmerVaultData } = useAccountData(farmer?.tokenVaultKey);
  const farmerVault = useMemo(() => {
    return farmerVaultData
      ? deserializeAccount(
          farmerVaultData.accountId,
          farmerVaultData.accountInfo.data
        )
      : farmerVaultData;
  }, [farmerVaultData]);
  const saberStakedAmount = useMemo(() => {
    return farmerVault
      ? new TokenAmount(pool.lpToken, farmerVault.amount)
      : null;
  }, [farmerVault, pool.lpToken]);

  // staked amount
  const stakedAmount = useMemo(() => {
    return vault
      ? new TokenAmount(pool.lpToken, vault.accountInfo.data.stakedBalance)
      : null;
  }, [pool.lpToken, vault]);
  const { stakeValue, stakeValueUSD } = useUserPlotShare({
    stakedAmount,
  });

  const sunnyTotalDeposits = useMemo(() => {
    const totes = sunnyPool?.accountInfo.data.totalLpTokenBalance;
    return totes ? new TokenAmount(pool.lpToken, totes) : totes;
  }, [pool.lpToken, sunnyPool?.accountInfo.data.totalLpTokenBalance]);

  const saberPlotStats = usePlotPriceStats({
    ...stats,
  });
  const { tvl } = usePlotPriceStats({
    totalDeposits: sunnyTotalDeposits ?? null,
  });
  const tvlUSD = plot.poolValueLockedUSD ?? null;

  const { sbrAPY, sunnyAPY, combinedAPY } = useSunnyPoolAPY({
    saberTVLUSD: saberPlotStats.tvlUSD,
    sunnyTVLUSD: tvlUSD,
  });

  const tvlStr = useMemo(
    () => (tvl && currency ? formatCurrencySmart(tvl, currency) : null),
    [tvl, currency]
  );
  const tvlUSDStr = useMemo(
    () =>
      tvlUSD && currency && currency !== CurrencyMarket.USD
        ? formatCurrencySmart(tvlUSD, CurrencyMarket.USD)
        : null,
    [tvlUSD, currency]
  );

  const apyStr = useMemo(
    () =>
      tvl?.equalTo(0)
        ? "∞"
        : combinedAPY
        ? combinedAPY.asFraction.greaterThan(new Fraction(90, 1)) // 90 = 9000%
          ? `>${formatDisplayWithSoftLimit(9000, 1, 1)}%`
          : parseFloat(combinedAPY.asFraction.toFixed(10)).toLocaleString(
              undefined,
              {
                style: "percent",
              }
            )
        : "N/A",
    [combinedAPY, tvl]
  );

  const tvlDisplay =
    tvlUSDStr && currency !== CurrencyMarket.USD ? tvlUSDStr : tvlStr;

  const verticalItemTVL = (
    <VerticalItem
      css={css`
        min-width: 140px;
      `}
      className="noTablet"
    >
      {tvlDisplay && (
        <>
          <VerticalItemData>{tvlDisplay}</VerticalItemData>
          <VerticalItemLabel>TVL in old pools</VerticalItemLabel>
        </>
      )}
    </VerticalItem>
  );

  let apyTooltipMessage: string[] | null = null;

  if (tvlStr) {
    apyTooltipMessage = [
      sbrAPY
        ? `${formatDisplayWithSoftLimit(
            fractionToFloat(sbrAPY.asFraction.multiply(100)),
            1,
            3
          )}% APY from Saber`
        : null,
      sunnyAPY
        ? `${formatDisplayWithSoftLimit(
            fractionToFloat(sunnyAPY.asFraction.multiply(100)),
            1,
            3
          )}% APY from Sunny`
        : null,
    ].filter((s): s is string => !!s);
  }

  const verticalItemAPY = (
    <VerticalItem
      css={css`
        min-width: 48px;
      `}
    >
      {tvlStr && (
        <HoverTooltip message={apyTooltipMessage}>
          <VerticalItemData
            css={css`
              min-width: 75px;
            `}
          >
            {apyStr}
            {plot.status === PoolStatus.Pending ? (
              <ExclamationWrapper>
                <FaExclamationTriangle />
              </ExclamationWrapper>
            ) : null}
          </VerticalItemData>
          <VerticalItemLabel
            css={css`
              padding-right: 1px;
            `}
          >
            APY
          </VerticalItemLabel>
        </HoverTooltip>
      )}
    </VerticalItem>
  );

  // let tvlTooltipMessage: string[] | null = null;

  // if (saberStakedAmount?.greaterThan("0")) {
  //   tvlTooltipMessage = [
  //     "Some LP tokens are staked in Saber",
  //     "but not yet deposited in Sunny",
  //   ];
  // } else if (lpATA?.balance.greaterThan("0")) {
  //   tvlTooltipMessage = [
  //     "Some LP tokens in your wallet",
  //     "are not yet deposited in Sunny",
  //   ];
  // }

  const verticalItemDeposits =
    stakedAmount || saberStakedAmount || lpATA?.balance.greaterThan("0") ? (
      <VerticalItem
        css={css`
          min-width: 108px;
        `}
      >
        {tvlStr && (
          <HoverTooltip message={[]}>
            <VerticalItemData>
              {stakeValue ? (
                <>
                  {stakeValueUSD && currency !== CurrencyMarket.USD
                    ? formatCurrencySmart(stakeValueUSD, CurrencyMarket.USD)
                    : formatCurrencySmart(stakeValue, currency)}
                </>
              ) : (
                <>$0</>
              )}
            </VerticalItemData>
            <VerticalItemLabel>Your Deposits</VerticalItemLabel>
          </HoverTooltip>
        )}
      </VerticalItem>
    ) : (
      ""
    );

  const scalableStats: IScalableStats = [];

  // In tablet size, we show TVL in scalableStats instead of in the top
  scalableStats.push(
    <TabletOnly key={"tabletTVL"}>
      <ScalableStatTitle>Total Value Locked in Sunny</ScalableStatTitle>
      <ScalableStatData>{tvlDisplay}</ScalableStatData>
    </TabletOnly>
  );

  return (
    <AccordionWrapper
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          setOpen(false);
          reFocusButtonRef?.current?.focus();
        }
      }}
    >
      <Header
        className={isOpen ? "expanded" : "closed"}
        onClick={() => {
          setOpen(!isOpen);
          setHasbeenLoaded(true);
        }}
      >
        <PoolTitle>
          <PoolIcon tokens={pool.tokenIcons} />
          <PoolName>
            Saber {pool.name}
            <PoolNameMobileHiddenPart> LP</PoolNameMobileHiddenPart> (old)
          </PoolName>
        </PoolTitle>
        <RightWrapper>
          {verticalItemDeposits}
          {verticalItemTVL}
          {verticalItemAPY}
          <ActualButton
            ref={reFocusButtonRef}
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!isOpen);
              setHasbeenLoaded(true);
            }}
            className={isOpen ? "isOpen" : ""}
          >
            <ActualButtonTriangle
              className={isOpen ? "isOpen" : ""}
            ></ActualButtonTriangle>
          </ActualButton>
        </RightWrapper>
      </Header>

      <Bellows className={isOpen ? "expanded" : "closed"} tabIndex={-1}>
        {hasBeenLoaded ? (
          <SaberSubPage
            plot={plot}
            scalableStats={scalableStats}
            isOpen={isOpen}
          />
        ) : null}
      </Bellows>
      <AntiNegativeMargin />
    </AccordionWrapper>
  );
};

const AccordionWrapper = styled.div``;

const RightWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  ${breakpoints.tablet} {
    gap: 24px;
  }
  ${breakpoints.mobile} {
    gap: 16px;
  }
  ${breakpoints.tinymobile} {
    gap: 4px;
  }
`;
const VerticalItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  ${breakpoints.tablet} {
    &.noTablet {
      display: none;
    }
  }
`;
const TabletOnly = styled.div`
  display: none;

  ${breakpoints.tablet} {
    display: block;
  }
`;
const VerticalItemLabel = styled.div`
  text-align: center;
  font-size: 13px;
  color: #667;
  line-height: 1;
  white-space: nowrap;
  padding-top: 5px;
  padding-bottom: 8px;
`;

const VerticalItemData = styled.div`
  line-height: 18px;
  font-weight: 600;
  padding-top: 8px;
`;

const Bellows = styled.div`
  max-height: 0px;

  margin-top: -4px; // So we can reduce whitespace without affecting the tab outline

  transition: opacity 0.2s ease-in-out, max-height 0.2s ease-out;
  overflow: hidden;

  // So that the tooltips don't get clipped
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  opacity: 0;
  &.expanded {
    max-height: 1000px;
    opacity: 1;
  }

  a:focus,
  a:hover {
    text-decoration: underline;
  }
`;

const AntiNegativeMargin = styled.div`
  margin-top: 4px; // counteracts the negative margin top on Bellows
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  border: none;
  padding-left: 32px;
  padding-right: 32px;
  background: transparent;

  ${breakpoints.tablet} {
    padding-left: 24px;
    padding-right: 24px;
  }

  min-height: 74px;
  border-radius: 8px;

  &.closed:hover {
    // background: #fafafb;
  }
  &:active {
    background: #fafafb;
  }
  // transition: background: 0.2s;
`;
const ActualButton = styled.button`
  height: 36px;
  width: 36px;
  border-radius: 6px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 0.7em;
  color: #667;
  :focus {
  }
  :hover {
    background: #f3f3f3;
  }
  ${breakpoints.mobile} {
    display: none;
  }
`;
const ActualButtonTriangle = styled.span`
  :after {
    content: "▼";
    display: block;
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
  }
  &.isOpen:after {
    // content: "▲";
    transform: rotate(-180deg);
  }
`;

const PoolTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding-right: 16px;

  ${breakpoints.mobile} {
    padding-right: 12px;
  }
`;

const PoolName = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.bold};
  font-size: 16px;
  padding: 12px 0;
  line-height: 1.3;
`;

const PoolNameMobileHiddenPart = styled.span`
  ${breakpoints.mobile} {
    display: none;
  }
`;

const ExclamationWrapper = styled.span`
  margin-left: 4px;
  line-height: 14px;
  vertical-align: bottom;
  svg {
    color: hsl(45, 100%, 58%);
    height: 15px;
    width: 15px;
  }
`;
