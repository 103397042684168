import styled from "@emotion/styled";
import type { ReactElement } from "react";

import { breakpoints } from "../../../theme/breakpoints";

export type IScalableStats = Array<ReactElement | Element>;

export const ScalableStats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ScalableStatTitle = styled.div`
  font-size: 13px;
  color: #667;
  line-height: 1.4;
`;
export const ScalableStatData = styled.div`
  //   font-weight: 600;
  line-height: 1.45;
  font-size: 15px;
`;

// Needs to be flex-start beacuse some things dynamically populate and things then shift around
export const SubPageWrapperTwoCol = styled.div`
  display: grid;

  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  align-items: flex-start;

  padding: 26px 32px 24px 32px; // pixel perfection

  gap: 32px;

  ${breakpoints.tablet} {
    grid-template-columns: 1fr;
    gap: 24px;

    padding: 20px 24px 17px 24px; // actually give 2px more at top and 1px at bottom
  }
`;

export const SubPageLeftBox = styled.div`
  flex: 0 0 50%;
  flex-grow: 0;
`;
export const SubPageRightBox = styled.div`
  flex: 0 0 50%;
  flex-grow: 0;
`;

export const SubNavWrapper = styled.div`
  position: relative;
  z-index: 2;
  border-bottom: 1px solid #e8e8e9;
`;

export const SubNavInner = styled.div`
  padding: 0 32px;
  padding-top: 1px;
  ${breakpoints.mobile} {
    padding: 0;
  }
`;

export const SubNavItem = styled.button`
  appearance: none;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  margin-bottom: -1px;
  display: inline-block;
  padding: 8px 24px 8px 24px;

  cursor: pointer;
  &.active {
    border-bottom-color: #555;
  }
  transition: border 0.2s;

  ${breakpoints.tinymobile} {
    font-size: 14px;
  }
`;

export const SubSubPage = styled.div`
  display: none;
  &.active {
    display: block;
  }
`;
