import type { TokenAmount } from "@saberhq/token-utils";
import { useMemo } from "react";

import type { PoolID } from "../environments";
import type { KnownPool } from "../useEnvironment";
import { useEnvironment } from "../useEnvironment";
import { useUserAssociatedTokenAccounts } from "../useUserAssociatedTokenAccounts";

/**
 * Fetches all pools with the user's balance in the pool.
 * @returns
 */
export const useAllPoolsWithUserBalances = (): {
  pools: {
    id: PoolID;
    pool: KnownPool;
    /**
     * User pool balance.
     */
    userBalance: TokenAmount | null;
  }[];
  userBalancesLoading: boolean;
} => {
  const { pools } = useEnvironment();
  const poolsWithIDs = useMemo(() => Object.entries(pools), [pools]);
  const userBalances = useUserAssociatedTokenAccounts(
    poolsWithIDs.map(([_, pool]) => pool.lpToken)
  );

  const poolsRet = useMemo(() => {
    return poolsWithIDs.map(([poolID, pool]) => {
      const ata = userBalances.find((b) =>
        b?.balance.token.equals(pool.lpToken)
      );
      return {
        id: poolID as PoolID,
        pool,
        userBalance: ata?.balance ?? null,
      };
    });
  }, [poolsWithIDs, userBalances]);

  return {
    pools: poolsRet,
    userBalancesLoading: userBalances.findIndex((b) => !b) !== -1,
  };
};
