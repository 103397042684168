import styled from "@emotion/styled";
import type { Token } from "@saberhq/token-utils";
import { Fraction, TokenAmount } from "@saberhq/token-utils";
import Fuse from "fuse.js";
import React, { useMemo, useState } from "react";

import { useSettings } from "../../../contexts/settings";
import { Tags } from "../../../utils/builtinTokens";
import { CurrencyMarket, getMarketIfExists } from "../../../utils/currencies";
import { useEnvironment } from "../../../utils/useEnvironment";
import { useUserAssociatedTokenAccounts } from "../../../utils/useUserAssociatedTokenAccounts";
import type { IProps as IModalProps } from "../Modal";
import { Modal } from "../Modal";
import { TokenAmountDisplay } from "../TokenAmountDisplay";
import { TokenInfo } from "./TokenInfo";

type IProps = Omit<IModalProps, "children" | "title"> & {
  tokens?: readonly Token[];
  selectedToken?: Token;
  onSelect?: (token: Token) => void;
};

const isNotDust = (amount: TokenAmount): boolean => {
  const { token } = amount;
  const market = getMarketIfExists(token);
  if (market === CurrencyMarket.USD) {
    // USD threshold: 1 cent
    return amount.greaterThan(new Fraction(1, 100));
  }
  if (market === CurrencyMarket.BTC) {
    // BTC threshold: 100 sats
    return amount.greaterThan(new Fraction(100, 100_000_000));
  }
  return amount.greaterThan(0);
};

export const SelectTokenModal: React.FC<IProps> = ({
  tokens,
  onSelect,
  ...modalProps
}: IProps) => {
  const { includeWrapped } = useSettings();
  const { tokens: allTokensInEnv } = useEnvironment();
  const allTokensUnfiltered = tokens ?? allTokensInEnv;
  const userTokenAccounts = useUserAssociatedTokenAccounts(allTokensUnfiltered);

  const allTokens = allTokensUnfiltered?.filter((t) => {
    if (t.symbol === "SBR") {
      return false;
    }
    if (t.info.tags?.includes(Tags.DecimalWrapped)) {
      if (includeWrapped) {
        return true;
      }
      const balance = userTokenAccounts.find((account) =>
        account?.balance.token.equals(t)
      )?.balance;
      return balance && isNotDust(balance);
    }
    return true;
  });

  const fuse = useMemo(
    () =>
      new Fuse(allTokens, {
        keys: ["name", "symbol"],
      }),
    [allTokens]
  );

  const [query, setQuery] = useState<string>("");

  const result = query ? fuse.search(query).map((r) => r.item) : allTokens;

  return (
    <Modal title="Select a token" {...modalProps}>
      <SearchInput
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        placeholder="Search for token"
      />
      <Results>
        {result.map((token) => {
          const amount =
            userTokenAccounts.find((account) =>
              account?.balance.token.equals(token)
            )?.balance ?? new TokenAmount(token, 0);
          return (
            <TokenOption
              key={token.mintAccount.toString()}
              onClick={() => onSelect?.(token)}
            >
              <TokenInfo token={token} />
              <Balance>
                <TokenAmountDisplay
                  amount={amount}
                  numberFormatOptions={
                    amount.lessThan(new Fraction(1, 1_000))
                      ? {
                          minimumSignificantDigits: 3,
                        }
                      : {
                          maximumFractionDigits: 3,
                        }
                  }
                />
              </Balance>
            </TokenOption>
          );
        })}
      </Results>
    </Modal>
  );
};

const Balance = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text.default};
`;

const TokenOption = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 8px;
  cursor: pointer;
  padding: 0 12px;
  &:hover {
    background: ${({ theme }) => theme.colors.modal.item.base.hover};
  }
`;

const Results = styled.div`
  margin-top: 36px;
  display: grid;
  gap: 4px;
`;

const SearchInput = styled.input`
  caret-color: ${({ theme }) => theme.colors.text.accent};
  color: ${({ theme }) => theme.colors.text.bold};
  &::placeholder {
    color: ${({ theme }) => theme.colors.text.muted};
  }

  outline: none;
  background: none;
  border: none;
  font-size: 20px;
  line-height: 16px;
`;
