import styled from "@emotion/styled";

interface Props {
  className?: string;
}

export const LoadingShimmer: React.FC<Props> = ({ className }: Props) => {
  return (
    <LoadingShimmerWrapper className={className}>
      <Shimmer />
    </LoadingShimmerWrapper>
  );
};

const LoadingShimmerWrapper = styled.div`
  position: relative;
  background: #eee;
  border-radius: 8px;
  padding: 12px 16px;
  overflow: hidden;
  display: block;
  line-height: 1.2;
`;

const Shimmer = styled.span`
  position: absolute;
  left: -200px;
  right: 400px;
  top: 0;
  bottom: 0;
  width: 200px;
  background-image: linear-gradient(
    58deg,
    rgba(238, 238, 238, 0) 25%,
    rgba(255, 255, 255, 0.5) 46%,
    rgba(255, 255, 255, 0.5) 54%,
    rgba(238, 238, 238, 0) 75%
  );

  background-repeat: no-repeat;
  pointer-events: none;

  z-index: 0;
  animation: 1.8s linear 0s infinite running gary;
  @keyframes gary {
    from {
      transform: translateX(-200%);
    }
    to {
      transform: translateX(200%);
    }
  }
`;
