import { useAccountsData } from "@saberhq/sail";
import type { Token } from "@saberhq/token-utils";
import {
  deserializeAccount,
  NATIVE_MINT,
  TokenAmount,
} from "@saberhq/token-utils";
import { useConnectedWallet } from "@saberhq/use-solana";
import * as Sentry from "@sentry/react";
import type { PublicKey } from "@solana/web3.js";
import { useMemo } from "react";

import { useSDK } from "../contexts/sdk";
import type { ProgramAddressInput } from "./useProgramAddresses";
import { ProgramAddressType, useProgramAddresses } from "./useProgramAddresses";

/**
 * A token account that may or may not exist.
 */
export interface AssociatedTokenAccount {
  key: PublicKey;
  balance: TokenAmount;
  isInitialized?: boolean;
}

export const useUserAssociatedTokenAccounts = (
  tokens: readonly (Token | undefined)[]
): readonly (AssociatedTokenAccount | undefined)[] => {
  const { nativeBalance } = useSDK();
  const wallet = useConnectedWallet();
  const owner = wallet?.publicKey;

  const memoTokens = useMemo(
    () => tokens,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(tokens.map((tok) => tok?.mintAccount.toString()))]
  );

  const programAddresses = useMemo(
    () =>
      memoTokens.map((t): ProgramAddressInput | null =>
        t && owner
          ? {
              type: ProgramAddressType.ATA,
              path: [t.mintAccount, owner],
            }
          : null
      ),
    [memoTokens, owner]
  );
  const userTokenAccountKeys = useProgramAddresses(programAddresses);

  const accountsData = useAccountsData(userTokenAccountKeys);

  return useMemo(
    () =>
      accountsData.map((datum, i) => {
        const userTokenAccountKey = userTokenAccountKeys[i];
        if (!userTokenAccountKey) {
          return undefined;
        }
        const token = memoTokens[i];
        if (!token) {
          return undefined;
        }
        if (token.mintAccount.equals(NATIVE_MINT)) {
          return {
            key: userTokenAccountKey,
            balance: nativeBalance ?? new TokenAmount(token, 0),
            isInitialized: !!datum,
          };
        }
        try {
          const parsed = datum
            ? deserializeAccount(datum.accountId, datum.accountInfo.data)
            : null;
          return {
            key: userTokenAccountKey,
            balance: parsed
              ? new TokenAmount(token, parsed.amount)
              : new TokenAmount(token, 0),
            isInitialized: !!datum,
          };
        } catch (e) {
          console.warn(
            `Error parsing ATA ${datum?.accountId.toString() ?? "(unknown)"}`,
            e
          );
          Sentry.captureException(e, {
            extra: {
              accountOwner: datum?.accountInfo.owner.toString(),
              bufferLength: datum?.accountInfo.data.length,
              accountID: datum?.accountId.toString(),
            },
          });
          return undefined;
        }
      }),
    [accountsData, memoTokens, nativeBalance, userTokenAccountKeys]
  );
};
