import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";

import { breakpoints } from "../../../../theme/breakpoints";
import { SlowRotatingSunLogo } from "../../../common/LoadingSpinner";
import { ConnectedWallet } from "./ConnectedWallet";

export const TopBar: React.FC = () => {
  return (
    <WrapperOfWrapper>
      <Wrapper>
        <div
          css={css`
            // flex: 1;
          `}
        >
          <Link
            to="/"
            css={(theme: Theme) => css`
              display: inline-block;
              color: ${theme.colors.brand.logo};

              :hover {
                color: ${theme.colors.brand.logo};
              }
            `}
            tabIndex={-1}
          >
            <LogoWrapper>
              <SlowRotatingSunLogo />
              <LogoText>
                Sunny
                <span
                  css={css`
                    ${breakpoints.tablet} {
                      display: none;
                    }
                  `}
                >
                  {" "}
                  Aggregator
                </span>
              </LogoText>
            </LogoWrapper>
          </Link>
        </div>

        <Right>
          <ConnectedWallet />
        </Right>
      </Wrapper>
    </WrapperOfWrapper>
  );
};

const WrapperOfWrapper = styled.div`
  background: #000;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 98px;

  max-width: 1060px;
  margin: 0 auto;

  background: #000;

  padding: 24px 32px;
  ${breakpoints.mobile} {
    padding: 24px 24px;
  }
`;

const Right = styled.div`
  // flex: 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 30px;
    height: 30px;
  }
`;

const LogoText = styled.h1`
  color: #fff;
  font-size: 21px;
  margin-left: 12px;
  margin-bottom: 0;
`;
