import { Fraction, Percent, TokenAmount } from "@saberhq/token-utils";
import { SBR_ADDRESS, SUNNY_ADDRESS } from "@sunnyaggregator/sunny-sdk";
import { useMemo } from "react";

import { usePlot } from "../../../contexts/plot";
import { usePrices } from "../../../contexts/prices";
import { CurrencyMarket } from "../../../utils/currencies";
import { useToken } from "../../../utils/farming/useToken";

interface UseSunnyPoolAPY {
  sbrAPY: Percent | null;
  sunnyAPY: Percent | null;
  combinedAPY: Percent | null;
}

interface Rewards {
  rewardsPerDayUSD: Fraction;
  tvlUSD: Fraction;
}

export const useSunnyPoolAPY = ({
  saberTVLUSD,
  sunnyTVLUSD,
}: {
  saberTVLUSD: Fraction | null;
  sunnyTVLUSD: Fraction | null;
}): UseSunnyPoolAPY => {
  const { token: sbr } = useToken(SBR_ADDRESS);
  const { token: sunny } = useToken(SUNNY_ADDRESS);
  const {
    prices: { [CurrencyMarket.SUNNY]: sunnyPriceUSD },
    sbrPriceUSD,
  } = usePrices();
  const { sunnyPool } = usePlot();

  return useMemo(() => {
    if (!saberTVLUSD || !sunnyTVLUSD) {
      return {
        sbrAPY: new Percent(0),
        sunnyAPY: new Percent(0),
        combinedAPY: new Percent(0),
      };
    }

    const sbrPerDay = sunnyPool?.plot.accountInfo.data.dailyRewardsRate;
    const sunnyPerDay = sunnyPool?.quarry.accountInfo.data.dailyRewardsRate;

    const sbrRewardsPerDayUSD =
      sbrPriceUSD && sbrPerDay && sbr
        ? sbrPriceUSD.multiply(new TokenAmount(sbr, sbrPerDay))
        : new Fraction(0);

    const sunnyRewardsPerDayUSD =
      sunnyPriceUSD.price && sunnyPerDay && sunny
        ? sunnyPriceUSD.price.multiply(new TokenAmount(sunny, sunnyPerDay))
        : new Fraction(0);

    return {
      sbrAPY: sbrRewardsPerDayUSD
        ? calculateAPY(sbrRewardsPerDayUSD, saberTVLUSD)
        : null,
      sunnyAPY: sunnyRewardsPerDayUSD
        ? calculateAPY(sunnyRewardsPerDayUSD, sunnyTVLUSD)
        : null,
      // apy should be based on the amount you'd get from staking into Sunny
      combinedAPY: calculateCombinedAPY(
        [
          sunnyRewardsPerDayUSD
            ? {
                rewardsPerDayUSD: sunnyRewardsPerDayUSD,
                tvlUSD: sunnyTVLUSD,
              }
            : null,
          sbrRewardsPerDayUSD
            ? {
                rewardsPerDayUSD: sbrRewardsPerDayUSD,
                tvlUSD: saberTVLUSD,
              }
            : null,
        ].filter((x): x is Rewards => !!x)
      ),
    };
  }, [
    saberTVLUSD,
    sbr,
    sbrPriceUSD,
    sunny,
    sunnyPool?.plot.accountInfo.data.dailyRewardsRate,
    sunnyPool?.quarry.accountInfo.data.dailyRewardsRate,
    sunnyPriceUSD.price,
    sunnyTVLUSD,
  ]);
};

const calculateCombinedAPY = (rewards: Rewards[]): Percent | null => {
  const dpys = rewards.map(({ rewardsPerDayUSD, tvlUSD }) => {
    if (tvlUSD.equalTo(0)) {
      return new Percent(0);
    }
    return rewardsPerDayUSD.divide(tvlUSD);
  });
  const dpy = dpys.reduce((acc, el) => acc.add(el), new Percent(0));
  const num = parseFloat(dpy.add(1).asFraction.toFixed(10)) ** 365 - 1;
  const numerator = Math.floor(num * 10_000_000_000);
  if (Number.isFinite(numerator)) {
    return new Percent(numerator, 10_000_000_000);
  }
  return null;
};

const calculateAPY = (
  rewardsPerDayUSD: Fraction,
  tvlUSD: Fraction
): Percent | null => {
  return calculateCombinedAPY([{ rewardsPerDayUSD, tvlUSD }]);
};
