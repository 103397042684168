import styled from "@emotion/styled";
import { ErrorBoundary } from "@sentry/react";
import type { ReactNode } from "react";
import React from "react";

import { AdvancedOptionsButton } from "../../common/AdvancedOptionsButton";

interface IProps {
  right?: React.ReactNode;
  hideOptions?: boolean;
  children: ReactNode | ReactNode[];
}

export const PageLayout: React.FC<IProps> = ({
  children,
  hideOptions,
  right,
}: IProps) => {
  return (
    <PageContainer>
      <PageTitleWrapper>
        {hideOptions !== true && <AdvancedOptionsButton />}
        {right}
      </PageTitleWrapper>
      <ErrorBoundary
        fallback={
          <ErrorMessage>
            An error occurred while loading this page.
          </ErrorMessage>
        }
      >
        {children}
      </ErrorBoundary>
    </PageContainer>
  );
};

const ErrorMessage = styled.p`
  color: red;
`;

const PageContainer = styled.div``;

const PageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
