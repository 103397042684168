import styled from "@emotion/styled";
import type { ReactNode } from "react";
import React from "react";

import { PageLayout } from "./PageLayout";
import { TopBar } from "./TopBar";

interface IProps {
  sideNav?: React.ReactNode;
  hideOptions?: boolean;
  right?: React.ReactNode;
  children: ReactNode | ReactNode[];
}

export const MainLayout: React.FC<IProps> = ({
  children,
  hideOptions,
  right,
}: IProps) => {
  return (
    <PageWrapper>
      <TopBar />
      <PageLayout hideOptions={hideOptions} right={right}>
        {children}
      </PageLayout>
    </PageWrapper>
  );
};

const PageWrapper = styled.div``;
