import { useSail } from "@saberhq/sail";
import type {
  IExchange,
  IExchangeInfo,
  StableSwap,
} from "@saberhq/stableswap-sdk";
import { calculateSwapPrice } from "@saberhq/stableswap-sdk";
import type { Price } from "@saberhq/token-utils";
import { Fraction } from "@saberhq/token-utils";
import { message } from "antd";
import { useCallback, useMemo } from "react";
import { createContainer } from "unstated-next";

import { usePrices } from "../contexts/prices";
import { useRouter } from "../contexts/router";
import { CurrencyMarket, getMarket } from "./currencies";

interface IUseStableSwap {
  swap?: StableSwap;
  exchange?: IExchange;
  exchangeInfo?: IExchangeInfo;
  refreshSwapState: () => Promise<StableSwap | null>;

  /**
   * Currency of the LP token.
   */
  currency: CurrencyMarket;
  /**
   * Price of 1 LP token, assuming tokens have value = 1.
   */
  virtualPrice?: Fraction;
  /**
   * Price of token 1 in terms of token 0.
   */
  unitPrice?: Price;
  /**
   * Price of the currency in USD
   */
  currencyPriceUSD: Fraction | null;
}

type UseStableSwapArgs = { exchange?: IExchange };

const useStableSwapInternal = ({
  exchange,
}: UseStableSwapArgs = {}): IUseStableSwap => {
  const { refetch } = useSail();
  const { exchangeMap, getExchangeInfo } = useRouter();
  const exchangeInfo = useMemo(
    () => (exchange ? getExchangeInfo(exchange) : null),
    [exchange, getExchangeInfo]
  );

  const swap = exchangeInfo?.swap;

  const refreshSwapState = useCallback(async () => {
    if (!exchange) {
      return null;
    }
    try {
      await refetch(exchange.swapAccount);
      return exchangeMap[exchange.lpToken.mintAccount.toString()]?.swap ?? null;
    } catch (e) {
      void message.error(`Error loading swap: ${(e as Error).message}`);
      return null;
    }
  }, [exchange, refetch, exchangeMap]);

  const currency =
    exchange?.tokens.map((tok) => getMarket(tok))[0] ?? CurrencyMarket.USD;

  const { prices } = usePrices();

  const { price: currencyPriceUSD } = useMemo(() => {
    return prices[currency] ?? new Fraction(1);
  }, [prices, currency]);

  const unitPrice = useMemo(
    () => (exchangeInfo ? calculateSwapPrice(exchangeInfo.info) : undefined),
    [exchangeInfo]
  );

  return {
    refreshSwapState,
    swap: swap ?? undefined,
    exchange,
    exchangeInfo: exchangeInfo?.info,
    currency,
    virtualPrice: exchangeInfo?.virtualPrice ?? undefined,
    unitPrice,
    currencyPriceUSD,
  };
};

export const { Provider: StableSwapProvider, useContainer: useStableSwap } =
  createContainer(useStableSwapInternal);
