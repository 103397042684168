import { zip } from "lodash";
import { useEffect, useMemo, useState } from "react";

import type { RewardRates } from "./calculateRewardRates";

export interface PreciseClaimableAmounts {
  pools: (number | null)[];
  total: number;
}

export const useGetPreciseClaimableAmounts = (
  rates: RewardRates | null
): (() => PreciseClaimableAmounts | null) => {
  return useMemo(() => {
    if (!rates || !rates.amountsT0.sum) {
      return () => null;
    }

    const initialTotalAmount = parseFloat(rates.amountsT0.sum.toExact());
    const initialPoolAmounts = zip(
      rates.amountsT0.allClaimable.map((c) =>
        c ? parseFloat(c.toExact()) : null
      ),
      rates.pools
    );
    return () => {
      const secondsElapsed = (Date.now() - rates.timeT0Ms) / 1_000;
      const pools = initialPoolAmounts.map(
        ([initialPoolAmount, poolRatePerSecond]) =>
          typeof initialPoolAmount === "number" &&
          typeof poolRatePerSecond === "number"
            ? initialPoolAmount + poolRatePerSecond * secondsElapsed
            : null
      );
      const total = initialTotalAmount + rates.total * secondsElapsed;
      return {
        pools,
        total,
      };
    };
  }, [rates]);
};

export const useClaimableAmounts = (
  getter: () => PreciseClaimableAmounts | null
): PreciseClaimableAmounts | null => {
  const [amounts, setAmounts] = useState<PreciseClaimableAmounts | null>(null);
  useEffect(() => {
    const interval = setInterval(() => {
      setAmounts(getter());
    }, 8);
    return () => clearInterval(interval);
  }, [getter]);
  return amounts;
};
