import "wicg-inert";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { u64 } from "@saberhq/token-utils";
import { Percent } from "@saberhq/token-utils";
import React, { useMemo, useState } from "react";

import { PlotProvider } from "../../../contexts/plot";
import type { SunnyPool } from "../../../contexts/sunnyPools";
import { formatPercent } from "../../../utils/format";
import { StableSwapProvider } from "../../../utils/useStableSwap";
import type { IScalableStats } from "../VaultsSub/SubPageCommon";
import { DepositWidget } from "./DepositWidget";
import { RewardsWidget } from "./RewardsWidget";
import {
  ScalableStatData,
  ScalableStats,
  ScalableStatTitle,
  SubNavInner,
  SubNavItem,
  SubNavWrapper,
  SubPageLeftBox,
  SubPageRightBox,
  SubPageWrapperTwoCol,
  SubSubPage,
} from "./SubPageCommon";
import { WithdrawWidget } from "./WithdrawWidget";

declare module "react" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> {
    inert?: string | null;
  }
}

interface Props {
  plot: SunnyPool;
  scalableStats: IScalableStats;
  /**
   * Useful for changing keyboard navigation
   */
  isOpen: boolean;
}

type SubSubPage = "deposit" | "withdraw" | "rewards";

export const SaberSubPage: React.FC<Props> = ({
  plot,
  scalableStats,
  isOpen,
}: Props) => {
  const [subSubPage, setSubSubPage] = useState<SubSubPage>("withdraw");

  const { sunnyPool } = plot;

  const performanceFee = useMemo(() => {
    const megaBPS = (
      sunnyPool.accountInfo.data.fees.claimFee as { megaBps: u64 }
    ).megaBps;
    return new Percent(megaBPS, 1_000_000 * 10_000);
  }, [sunnyPool.accountInfo.data.fees.claimFee]);

  const vaultKey = plot.vault?.accountId.toBase58();

  return (
    // Use the wicg-inert polyfill.
    // The inert only works inside SubPage, and can't be used in the parent...
    <InertWrapper inert={isOpen ? null : ""}>
      <SubNavWrapper>
        <SubNavInner>
          <SubNavItem
            className={subSubPage === "withdraw" ? "active" : ""}
            onClick={() => setSubSubPage("withdraw")}
          >
            Withdraw
          </SubNavItem>
          <SubNavItem
            className={subSubPage === "rewards" ? "active" : ""}
            onClick={() => setSubSubPage("rewards")}
          >
            Rewards
          </SubNavItem>
        </SubNavInner>
      </SubNavWrapper>
      <SubPageWrapperTwoCol>
        <SubPageLeftBox
          css={css`
            flex: 0 0 60%;
          `}
        >
          <StableSwapProvider
            initialState={{
              exchange: plot.pool,
            }}
          >
            <PlotProvider
              initialState={{
                plot,
              }}
            >
              <SubSubPage className={subSubPage === "deposit" ? "active" : ""}>
                <WidthLimiter>
                  <SubPanelTitle>Deposit Saber LP into Sunny</SubPanelTitle>
                  <DepositWidget />
                  <Disclaimer>
                    Saber LP can be obtained by{" "}
                    <a
                      href={`https://app.saber.so/#/pools/${plot.poolID}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      providing liquidity on Saber using their app
                    </a>
                    {"."} If you already have Saber LP staked in a Saber farm,
                    you will need to{" "}
                    <a
                      href="https://app.saber.so/#/farms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      unstake from Saber
                    </a>{" "}
                    before staking into Sunny.
                  </Disclaimer>
                </WidthLimiter>
              </SubSubPage>
              <SubSubPage className={subSubPage === "withdraw" ? "active" : ""}>
                <WidthLimiter>
                  <SubPanelTitle>Withdraw Saber LP from Sunny</SubPanelTitle>
                  <WithdrawWidget />
                  <Disclaimer></Disclaimer>
                </WidthLimiter>
              </SubSubPage>
              <SubSubPage className={subSubPage === "rewards" ? "active" : ""}>
                <WidthLimiter>
                  <SubPanelTitle>
                    Your Old Rewards for {plot.pool.name}
                  </SubPanelTitle>
                  <RewardsWidget />
                  <Disclaimer>
                    <p>
                      Using "Claim old rewards" will only claim rewards from old
                      pools.
                    </p>
                  </Disclaimer>
                </WidthLimiter>
              </SubSubPage>
            </PlotProvider>
          </StableSwapProvider>
        </SubPageLeftBox>
        <SubPageRightBox>
          <Description>
            <PendingPoolMessage>
              This is the old pool. You can only withdraw from here. To deposit,
              use the latest app at{" "}
              <a href="https://sunny.ag">https://sunny.ag</a>
            </PendingPoolMessage>
          </Description>
          <ScalableStats>
            {scalableStats}
            <div>
              <ScalableStatTitle>Platform</ScalableStatTitle>
              <ScalableStatData>
                <a href="https://saber.so/" target="_blank" rel="noreferrer">
                  Saber: Solana AMM and DEX
                </a>
              </ScalableStatData>
            </div>
            <div>
              <ScalableStatTitle>Pool</ScalableStatTitle>
              <ScalableStatData>
                <a
                  href={`https://app.saber.so/#/pools/${plot.pool.id}/deposit`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Saber {plot.pool.name} LP
                </a>
              </ScalableStatData>
            </div>
            {vaultKey ? (
              <div>
                <ScalableStatTitle>
                  Your Vault Key (relevant for Saber airdrop)
                </ScalableStatTitle>
                <ScalableStatData
                  css={css`
                    word-break: break-all;
                    @media (max-width: 1000px) {
                      font-size: 12px;
                    }
                  `}
                >
                  {vaultKey}
                </ScalableStatData>
              </div>
            ) : null}
            <div>
              <ScalableStatTitle>Sunny fees</ScalableStatTitle>
              <ScalableStatData>
                Withdraw fee: 0%
                <br />
                Performance fee: {formatPercent(performanceFee)}{" "}
                <sup>
                  <a
                    href="https://medium.com/sunny-aggregator/sunny-governance-token-sunny-8f2b531f8f01#44d2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    (?)
                  </a>
                </sup>
              </ScalableStatData>
            </div>
          </ScalableStats>
        </SubPageRightBox>
      </SubPageWrapperTwoCol>
    </InertWrapper>
  );
};

const InertWrapper = styled.div``;

const Description = styled.div`
  font-size: 15px;
  margin-bottom: 1.4em;
  p {
    margin-bottom: 0.5em;
  }
`;

const Disclaimer = styled.div`
  margin-top: 18px;
  font-size: 13px;
  p:last-child {
    margin-bottom: 0;
  }
`;

const WidthLimiter = styled.div`
  max-width: 454px;
  margin: 0 auto;
`;

const SubPanelTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  line-height: 1.5;
  margin-bottom: 8px;
`;

const PendingPoolMessage = styled.p`
  padding: 1em 1.5em;
  border-radius: 8px;
  background: hsl(45, 100%, 65%);
`;
